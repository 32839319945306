import React, { FC } from 'react';
import styles from './_styles.module.scss';
import PhoneImage from './PhoneImage';
import { ReactComponent as ArrowsSvg } from '../../../../assets/icons/why_us_deck_arrows.svg';
import { ReactComponent as LineSvg } from '../../../../assets/icons/deck_vertor_bt.svg';
import WebSiteImg from '../../../../assets/images/wbsite_img_why_us.png';
import AppsImg from '../../../../assets/images/mob_apps_img_why_us.png';
import useWebContent from '../../../../core/hooks/useWebContent';

const PostLocationDeck: FC = () => {
  const [webContent] = useWebContent();

  return (
    <div className={styles.info_deck}>
      <ArrowsSvg className={styles.arrows_top} />

      <div className={styles.img_list}>
        <div className={styles.img_wrap}>
          <div className={styles.loc_item}>
            <span>{webContent.posting_location_one_web}</span>
          </div>
          <img className={styles.img} src={WebSiteImg} alt='web site img' />
        </div>
        <div className={styles.img_wrap}>
          <PhoneImage />
        </div>
        <div className={styles.img_wrap}>
          <div className={styles.loc_item}>
            <span>{webContent.posting_location_three_web}</span>
          </div>
          <img className={styles.img} src={AppsImg} alt='mob apps img' />
        </div>
      </div>

      <LineSvg className={styles.arrow_bt} />
    </div>
  );
};

export default PostLocationDeck;
