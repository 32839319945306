import React, { FC, useCallback } from 'react';
import styles from './_styles.module.scss';
import InfoGridCard from '../../../containers/InfoGridCard/InfoGridCard';
import UIInput from '../../UI/UIInput/UIInput';
import { useAppDispatch, useAppSelector } from '../../../core/hooks/reduxHooks';
import UIRadioButton from '../../UI/UIRadioButton/UIRadioButton';
import UIButton from '../../UI/UIButton/UIButton';
import {
  setEmployerChosenValue,
  setVerifyEmail,
  switchChosenEmployerNotify,
} from '../../../redux/reducers/api/UserInformationSlice';
import { ProfileEmployerFormType } from '../../../core/models/models';
import { setLoading } from '../../../redux/reducers/ui/HandlerSlice';
import {
  updateEmployerProfileInfo,
  verifyEmailHandler,
} from '../../../redux/thunk/profile';
import { openModal } from '../../../redux/reducers/ui/ModalDialogSlice';
import { setUserPhoneNumber } from '../../../redux/reducers/api/UserTokenSlice';

const EmployerProfile: FC = () => {
  const dispatch = useAppDispatch();
  const { employerInformation } = useAppSelector(
    state => state.userInformationReducer
  );

  const onSubmit = useCallback(
    (event: React.SyntheticEvent) => {
      event.preventDefault();

      if (
        employerInformation.name &&
        employerInformation.email &&
        employerInformation.chosenNotifications
      ) {
        dispatch(setLoading(true));

        const profile: ProfileEmployerFormType = {
          name: employerInformation.name,
          email: employerInformation.email,
          chosenNotifications: employerInformation.chosenNotifications,
        };

        const profileData = new FormData();

        for (const key in profile) {
          if (Array.isArray(profile[key as keyof typeof profile])) {
            profileData.append(
              key,
              JSON.stringify(profile[key as keyof typeof profile])
            );
          } else {
            profileData.append(
              key,
              profile[key as keyof typeof profile] as string
            );
          }
        }

        dispatch(updateEmployerProfileInfo(profileData));
      }
    },
    [employerInformation, dispatch]
  );

  return (
    <form className={styles.profile_form} onSubmit={onSubmit}>
      <InfoGridCard
        className={styles.profile_grid}
        heading={'Information'}
        logo={'info'}
        role='employer'
      >
        <div className={styles.inputs_wrapper}>
          <UIInput
            id={'employer_name'}
            title='Name:'
            value={employerInformation.name}
            onChange={event =>
              dispatch(
                setEmployerChosenValue({
                  key: 'name',
                  value: event.target.value,
                })
              )
            }
            borderColor={'secondary'}
          />
          <UIInput
            id={'employer_email'}
            title='Email:'
            type='email'
            button={
              ((!employerInformation.is_verified_email ||
                (employerInformation.is_verified_email &&
                  employerInformation.email !==
                    employerInformation.verified_email)) &&
                'Verify') ||
              undefined
            }
            value={employerInformation.email}
            onChange={event =>
              dispatch(
                setEmployerChosenValue({
                  key: 'email',
                  value: event.target.value,
                })
              )
            }
            buttonHandler={() => {
              if (employerInformation.email?.length) {
                dispatch(
                  verifyEmailHandler({ email: employerInformation.email })
                );
                dispatch(setVerifyEmail('employer'));
              }
            }}
            borderColor={'secondary'}
          />
          <UIInput
            id={'employer_phone'}
            title='Phone:'
            phone
            disabled={true}
            button='Change'
            value={employerInformation.hpNumber}
            onChange={event =>
              dispatch(
                setEmployerChosenValue({
                  key: 'hpNumber',
                  value: event.target.value,
                })
              )
            }
            buttonHandler={() => dispatch(openModal('newPhone'))}
          />
          {
            <UIInput
              id={'employer_pass'}
              title='Password:'
              type='password'
              value={'88888888888'}
              disabled={true}
              button='Change'
              buttonHandler={() => {
                if (employerInformation.hpNumber) {
                  dispatch(setUserPhoneNumber(employerInformation.hpNumber));
                  dispatch(openModal('updatePasswordProfile'));
                }
              }}
            />
          }
        </div>

        <div className={styles.notify_form_group}>
          <p className={styles.notify_heading}>
            How would you like to receive notification after candidates applied
            for the job?
          </p>

          <ul className={styles.notify_list}>
            {employerInformation.notifications &&
              employerInformation.notifications.map(notify => (
                <li className={styles.notify_item} key={notify}>
                  <UIRadioButton
                    checked={employerInformation.chosenNotifications?.includes(
                      notify
                    )}
                    onChange={event =>
                      dispatch(
                        switchChosenEmployerNotify({
                          value: notify,
                          add: event.target.value === '1',
                        })
                      )
                    }
                    name={notify}
                    label={notify}
                    id={notify}
                  />
                </li>
              ))}
          </ul>
        </div>
      </InfoGridCard>

      <div className={styles.profile_buttons}>
        <UIButton
          title='Delete profile'
          classModificator={styles.delete_btn}
          callback={() => dispatch(openModal('deleteUser'))}
        />
        <UIButton
          title='Save'
          type='submit'
          classModificator={styles.save_btn}
        />
      </div>
    </form>
  );
};

export default EmployerProfile;
