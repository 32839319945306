import React, { FC, useMemo } from 'react';
import styles from './_styles.module.scss';
import Accordion from './Accordion/Accordion';
import useWebContent from '../../../../core/hooks/useWebContent';

const questionsAnswers = [
  {
    question: '1. How do I post jobs?',
    answer: `You can post jobs via our website or telegram bot @SgJobBot`,
  },
  {
    question: '2. How do I receive profile of job candidates?',
    answer:
      'You can view job candidates profile via our website or telegram bot @SgJobBot.',
  },
  {
    question:
      '3. How long will the job advertisement be open to receive application?',
    answer: `The job advertisement will be open for 15 days. You can extend the job advertisement by purchasing bump.`,
  },
];

const FaqSection: FC = () => {
  const [webContent] = useWebContent();

  const accordionData = useMemo(
    () =>
      questionsAnswers.map((faq, inx) => {
        switch (inx) {
          case 0:
            return {
              question: webContent.faq_question_one_web,
              answer: webContent.faq_answer_one_web,
            };
          case 1:
            return {
              question: webContent.faq_question_two_web,
              answer: webContent.faq_answer_two_web,
            };
          case 2:
            return {
              question: webContent.faq_question_three_web,
              answer: webContent.faq_answer_three_web,
            };
          default:
            return faq;
        }
      }),
    [webContent]
  );

  return (
    <section id={'faq'} className={styles.faq_section}>
      <Accordion questionsAnswers={accordionData} />
    </section>
  );
};

export default FaqSection;
