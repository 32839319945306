import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styles from './_styles.module.scss';
import { useAppDispatch } from '../../../core/hooks/reduxHooks';
import { closeModal } from '../../../redux/reducers/ui/ModalDialogSlice';
import { drawerSlice } from '../../../redux/reducers/ui/DrawerSlice';
import {
  setFullTimeFilter,
  setPartTimeFilter,
} from '../../../redux/reducers/api/CandidateAssignmentsSlice';

const UIJobsTypeLink: FC<{
  goTo: string;
  title: string;
  jobType: 'Full-time' | 'Part-time';
  callback?: () => void;
}> = ({ goTo, title, jobType, callback }) => {
  const dispatch = useAppDispatch();
  const { closeDrawer } = drawerSlice.actions;

  return (
    <Link
      to={goTo}
      onClick={() => {
        if (callback) {
          callback();
        }

        dispatch(closeDrawer());
        if (jobType === 'Full-time') {
          dispatch(setFullTimeFilter());
        } else if (jobType === 'Part-time') {
          dispatch(setPartTimeFilter());
        }
      }}
      replace={true}
      className={`${styles.link} ${
        (jobType === 'Full-time' && styles.link__fullTime) || ''
      } ${(jobType === 'Part-time' && styles.link__partTime) || ''}`}
    >
      {title}
    </Link>
  );
};

export default UIJobsTypeLink;
