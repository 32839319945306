import React, { FC } from 'react';
import './JobDetailsDescription.scss';
import useWebContent from '../../../core/hooks/useWebContent';

interface JobDetailsDescriptionProps {
  description: string[];
}

const JobDetailsDescription: FC<JobDetailsDescriptionProps> = ({
  description,
}) => {
  const [webContent] = useWebContent();

  if (!description) {
    return null;
  }

  return (
    <div className='description'>
      <p className='description--title'>
        <b>{webContent.job_job_description_web}</b>
      </p>
      <p className='description--text'>{description.join(`\r\n`)}</p>
    </div>
  );
};

export default JobDetailsDescription;
