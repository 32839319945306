import React, { useEffect } from 'react';
// import { useLocation } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import Logout from '../../components/Logout/Logout';
import UINavLink from '../../components/UI/UINavLink/UINavLink';
import { adminLayoutLinks } from '../../core/constants/config';
import style from './_index.module.scss';
import { useAppSelector } from '../../core/hooks/reduxHooks';

const AdminLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isAdmin } = useAppSelector(state => state.userTokenReducer);

  if (!isAdmin) {
    return <></>;
  }

  return (
    <>
      {/* <div role='presentation' className={style.layout}> */}
      <div className={style.wrap}>
        <header className={style.header}>
          <span>Staff</span>
          <nav>
            {adminLayoutLinks.map(link => (
              <UINavLink
                key={`${link.to}${link.title}`}
                className={style.link}
                role={'admin'}
                end
                {...link}
              />
            ))}
          </nav>
          <Logout forUser='admin' />
        </header>
      </div>
      {children}
      <Footer />
    </>
  );
};

export default AdminLayout;
