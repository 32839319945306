import React, { useEffect, useCallback } from 'react';
import styles from '../_styles.module.scss';
import BurgerMenu from '../../../BurgerMenu/BurgerMenu';
import { useLocation, useMatch } from 'react-router-dom';
import Logo from '../../../Logo/Logo';
import MobileHeaderTitle from '../../../MobileHeaderTitle/MobileHeaderTitle';

const locationPathNames = [
  {
    path: '/profile',
    title: 'Profile',
  },
  {
    path: '/price',
    title: 'Price',
  },
  {
    path: '/view-job',
    title: 'View job',
  },
  {
    path: '/manage-jobs',
    title: 'Manage jobs',
  },
  {
    path: '/post-jobs',
    title: 'Post a job',
  },
  // {
  //   path: '/post-jobs/drafts',
  //   title: 'Drafts',
  // },
  {
    path: '/post-jobs/pending-approval',
    title: 'Pending approval',
  },
  {
    path: '/purchase-history',
    title: 'Purchase history',
  },
];

const MobileEmployerHeader = () => {
  const location = useLocation();
  const routeMatchMainPage = useMatch('/employer');

  // const match = useMatch({
  //   path: goTo,
  //   end: goTo.length === 1,
  // });
  //
  // const activeRoute = (routeName: any) => {
  //   return location.pathname === routeName;
  // };

  const currentTitle = useCallback(path => {
    const currentPathInfo = locationPathNames.find(loc =>
      path.includes(loc.path)
    );

    return currentPathInfo ? currentPathInfo.title : '';
  }, []);

  useEffect(() => {}, [location.pathname]);

  return (
    <div className={styles.header_mob_wrap}>
      <header className={`${styles.header_mob}`}>
        {routeMatchMainPage ? (
          <>
            <Logo goTo={'/employer'} />
            <BurgerMenu routes={'employer'} />
          </>
        ) : (
          <MobileHeaderTitle
            title={currentTitle(location.pathname)}
            role={'employer'}
            burgerMenu={true}
          />
        )}
      </header>
    </div>
  );
};

export default MobileEmployerHeader;
