import React, { FC, useCallback, useEffect, useState } from 'react';
import styles from './_styles.module.scss';
import JobsInfoListContainer from '../../../../containers/JobsInfoListContainer/JobsInfoListContainer';
import PostedJobList from './PostedJobList/PostedJobList';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../core/hooks/reduxHooks';
import PendingApprovalJobInfo from './PendingApprovalJobInfo/PendingApprovalJobInfo';
import { useWindowSize } from '../../../../core/hooks/useWindowSize';
import PendingApprovalCard from './PendingApprovalCard/PendingApprovalCard';
import UIPagination from '../../../UI/UIPagination/UIPagination';
import { breakpointForJobsList } from '../../../../core/constants/variables';
import { useSearchParams } from 'react-router-dom';
import { getPendingApprovalPosts } from '../../../../redux/thunk/employerAssignments';

const PendingApprovalPosts: FC = () => {
  const [isJobsPage, setJobsPage] = useState<number>(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const assignmentId = searchParams.get('assignment_id');

  const dispatch = useAppDispatch();
  const size = useWindowSize();

  const { isLoading, pendingApproval } = useAppSelector(
    state => state.postUserJobReducer
  );

  const changeSelectedJobId = useCallback((id: string) => {
    setSearchParams({ assignment_id: id });
  }, []);

  useEffect(() => {
    dispatch(getPendingApprovalPosts(isJobsPage));
  }, [isJobsPage]);

  useEffect(() => {
    if (
      !assignmentId &&
      pendingApproval.results.length > 0 &&
      size.width > breakpointForJobsList
    ) {
      changeSelectedJobId(pendingApproval.results[0].assignment_id);
    }
  }, [pendingApproval]);

  if (isLoading && !pendingApproval.results.length) {
    return (
      <section className={styles.pending_approval}>
        <div style={{ minHeight: '80vh' }}></div>
      </section>
    );
  }

  if (!pendingApproval.results.length) {
    return (
      <section className={styles.pending_approval}>
        <h3>No jobs are pending approval</h3>
      </section>
    );
  }

  if (size.width < breakpointForJobsList) {
    return (
      <section className={styles.pending_approval}>
        <ul className={styles.mob_job_list}>
          {pendingApproval.results.map(jobDetails => {
            return (
              <PendingApprovalCard
                key={jobDetails.assignment_id}
                job={jobDetails}
              />
            );
          })}
        </ul>
        <UIPagination
          currentPage={isJobsPage}
          totalPages={pendingApproval.totalPages}
          onChangePage={(event, page) => {
            setJobsPage(page);
          }}
        />
      </section>
    );
  }

  return (
    <section className={styles.pending_approval}>
      <JobsInfoListContainer>
        <PostedJobList
          selectedJobId={assignmentId}
          onClick={changeSelectedJobId}
        />
        <PendingApprovalJobInfo />

        <UIPagination
          currentPage={isJobsPage}
          totalPages={pendingApproval.totalPages}
          onChangePage={(event, page) => {
            setJobsPage(page);
          }}
        />
      </JobsInfoListContainer>
    </section>
  );
};

export default PendingApprovalPosts;
