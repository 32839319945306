import React, { FC, useEffect, useRef } from 'react';
import UIButton from '../../components/UI/UIButton/UIButton';
import './Search.scss';
import { useRouter } from '../../core/hooks/useRouter';
import UISearchInput from '../../components/UI/UISearchInput/UISearchInput';
import { useAppDispatch, useAppSelector } from '../../core/hooks/reduxHooks';
import {
  setActiveFilters,
  setAllFilters,
  setKeyword,
  switchActiveFiltersItem,
} from '../../redux/reducers/api/CandidateAssignmentsSlice';
import { UIMultipleSelect } from '../../components/UI/UIMultipleSelect/UIMultipleSelect';
import useDebounce from '../../core/hooks/useDebounce';
import { getAssignmentFilters } from '../../redux/thunk/assignments';
import {
  JobCategoriesTypes,
  LocationTypes,
  PreferredJobTypes,
} from '../../core/models/models';
import useWebContent from '../../core/hooks/useWebContent';

const Search: FC<{
  searchCallback?: (selectedReplace: boolean) => void;
  setJobTypes?: () => void;
}> = ({ searchCallback, setJobTypes }) => {
  const { navigate } = useRouter();
  const dispatch = useAppDispatch();

  const { activeFilters, possibleFilters } = useAppSelector(
    state => state.candidateAssignments
  );

  const initialRender = useRef(true);

  const [webContent] = useWebContent();

  const debouncedKeyWord = useDebounce(activeFilters.key_words, 800);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      if (searchCallback) {
        searchCallback(true);
      }
    }
  }, [debouncedKeyWord]);

  useEffect(() => {
    dispatch(getAssignmentFilters());
    // if (searchCallback) {
    //   searchCallback();
    // }
  }, []);

  const searchSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (searchCallback) {
      searchCallback(true);
    } else {
      navigate('/search');
    }
  };

  return (
    <form id={'searchForm'} onSubmit={searchSubmit} className='search'>
      <div className='search__field'>
        <UISearchInput
          name={'Job title'}
          label={webContent.search_bar_keyword_web}
          placeholder={'Job title, keyword'}
          value={activeFilters.key_words}
          onChange={e => dispatch(setKeyword(e.target.value))}
        />
        <UIMultipleSelect
          heading={webContent.search_bar_type_web}
          iconType={'jobs'}
          placeholder={'Types of jobs'}
          possibleList={possibleFilters.job_types}
          activeList={activeFilters.job_types}
          activeListName={'job_types'}
          onChangeList={(event, option: PreferredJobTypes) => {
            dispatch(
              switchActiveFiltersItem({
                array: 'job_types',
                value: option,
                add: event.target.checked,
              })
            );
          }}
          isAssignmentFilters
          allTypesChecked={possibleFilters.allJobTypes}
          allTypesTitle='All types of jobs'
          onAllTypesChange={event => {
            dispatch(
              setAllFilters({ id: 'allJobTypes', value: event.target.checked })
            );
            if (event.target.checked) {
              dispatch(
                setActiveFilters({
                  id: 'job_types',
                  value: possibleFilters.job_types,
                })
              );
            } else {
              dispatch(setActiveFilters({ id: 'job_types', value: [] }));
            }
          }}
          allTypesId='allJobTypes'
        />
        <UIMultipleSelect
          heading={webContent.search_bar_location_web}
          iconType={'location'}
          placeholder={'Locations'}
          possibleList={possibleFilters.regions}
          activeList={activeFilters.regions}
          activeListName={'regions'}
          onChangeList={(event, option: LocationTypes) => {
            dispatch(
              switchActiveFiltersItem({
                array: 'regions',
                value: option,
                add: event.target.checked,
              })
            );
          }}
          isAssignmentFilters
          allTypesChecked={possibleFilters.allRegions}
          allTypesTitle='Any locations'
          onAllTypesChange={event => {
            dispatch(
              setAllFilters({ id: 'allRegions', value: event.target.checked })
            );
            if (event.target.checked) {
              dispatch(
                setActiveFilters({
                  id: 'regions',
                  value: possibleFilters.regions,
                })
              );
            } else {
              dispatch(setActiveFilters({ id: 'regions', value: [] }));
            }
          }}
          allTypesId='allRegions'
        />
        <UIMultipleSelect
          heading={webContent.search_bar_categories_web}
          iconType={'industries'}
          placeholder={'Industries'}
          possibleList={possibleFilters.categories}
          activeList={activeFilters.categories}
          activeListName={'categories'}
          onChangeList={(event, option: JobCategoriesTypes) => {
            dispatch(
              switchActiveFiltersItem({
                array: 'categories',
                value: option,
                add: event.target.checked,
              })
            );
          }}
          isAssignmentFilters
          allTypesChecked={possibleFilters.allCategories}
          allTypesTitle='All industries'
          onAllTypesChange={event => {
            dispatch(
              setAllFilters({
                id: 'allCategories',
                value: event.target.checked,
              })
            );
            if (event.target.checked) {
              dispatch(
                setActiveFilters({
                  id: 'categories',
                  value: possibleFilters.categories,
                })
              );
            } else {
              dispatch(setActiveFilters({ id: 'categories', value: [] }));
            }
          }}
          allTypesId={'allCategories'}
        />
      </div>
      <div className='search__button-wrapper'>
        <UIButton
          title={'Search'}
          type={'submit'}
          classModificator={'Button--search'}
          // callback={() => {
          //   if (searchCallback) {
          //     searchCallback();
          //   } else {
          //     navigate('/search');
          //   }
          // }}
        />
      </div>
    </form>
  );
};

export default Search;
