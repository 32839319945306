import React, { FC, useCallback, useRef, useState } from 'react';
import styles from './_styles.module.scss';
import useOnClickOutside from '../../../core/hooks/useOnClickOutside';
import UISelectCheckbox from '../UISelectCheckbox/UISelectCheckbox';

interface UIFilterSelectBoxProps {
  list: string[];
  selectedOption: string;
  className?: string;
  setSelectedOption: (option: any) => void;
}

const UIFilterSelectBox: FC<UIFilterSelectBoxProps> = ({
  list,
  selectedOption,
  setSelectedOption,
  className,
}) => {
  const [isListOpen, setListOpen] = useState<boolean>(false);
  const refSelectBox = useRef<HTMLDivElement>(null);

  const closeDropdown = useCallback(() => setListOpen(false), []);

  useOnClickOutside(refSelectBox, closeDropdown);

  const toggleList = useCallback(() => {
    setListOpen(!isListOpen);
  }, [isListOpen]);

  const handleSort = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, option: string) => {
      setSelectedOption(option);
      setListOpen(false);
    },
    [setSelectedOption, isListOpen, selectedOption]
  );

  return (
    <div className={styles.dropdown_wrap} ref={refSelectBox}>
      <div className={`${styles.dropdown} ${className || ''}`}>
        <div className={styles.dropdown_header}>
          <button
            type='button'
            className={styles.dropdown_heading}
            onClick={() => toggleList()}
          >
            {selectedOption}
            <span
              className={`${styles.dropdown_arrow} ${
                isListOpen ? styles.dropdown_arrow_up : ''
              }`}
            />
          </button>
        </div>

        <ul
          className={`${styles.dropdown_list} ${
            isListOpen ? styles.dropdown_list_open : ''
          }`}
        >
          {list.map((option, index) => (
            <li key={option + index} className={styles.dropdown_item}>
              <UISelectCheckbox
                id={option + index}
                type='radio'
                title={option}
                checked={selectedOption === option}
                onChange={e => handleSort(e, option)}
                className={styles.dropdown_item_checkbox}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default UIFilterSelectBox;
