import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  adminAssignmentsTypes,
  assignmentInitialExpirationDate,
} from '../../../core/constants/api';
import { formatDate } from '../../../core/functions';
import {
  AdminAssignmentsOrder,
  AdminAssignment,
  IPreferredQualifications,
  JobCategoriesTypes,
  Pagination,
  PreferredJobTypes,
  AdminAssignmentsType,
  AdminAssignmentProposal,
  IReviewCandidatesResult,
} from '../../../core/models/models';

export interface AdminAssignmentsForm {
  chosen_category: JobCategoriesTypes;
  preferred_qualifications: IPreferredQualifications[];
  job_type: PreferredJobTypes | '';
  company_name: string;
  assignmentId?: string;
  job_title: string;
  job_location: string;
  days_and_hours: string;
  lowest_rate: string;
  highest_rate: string;
  job_description: string;
  client_name: string;
  client_contact_number: string;
  client_full_address: string;
  client_other_info: string;
  expiration_date: string;
}

export interface AssignmentsState {
  assignments: AdminAssignment[];
  current: AdminAssignment | null;
  proposals: AdminAssignmentProposal[] | null;
  pagination: Pagination;
  orderBy: AdminAssignmentsOrder;
  search: string;
}

type AdminAssignmentsState = {
  [K in AdminAssignmentsType]: AssignmentsState;
} & {
  form: AdminAssignmentsForm;
  jobReviewCandidatesAdmin: IReviewCandidatesResult;
  jobReviewCandidatesAdminLoading: boolean;
};

const initialState: AdminAssignmentsState = {
  form: {
    chosen_category: 'Other',
    preferred_qualifications: ['No preference'],
    job_type: '',
    company_name: '',
    assignmentId: '',
    job_title: '',
    job_location: '',
    days_and_hours: '',
    lowest_rate: '',
    highest_rate: '',
    job_description: '',
    client_name: '',
    client_contact_number: '',
    client_full_address: '',
    client_other_info: '',
    expiration_date: formatDate(assignmentInitialExpirationDate),
  },
  jobReviewCandidatesAdmin: {
    pageNumber: 1,
    pageSize: 6,
    totalPages: 1,
    total: 1,
    results: [],
  },
  jobReviewCandidatesAdminLoading: false,
  ...adminAssignmentsTypes.reduce(
    (acc, current) => ({
      ...acc,
      [current]: {
        current: null,
        assignments: [],
        pagination: {
          pageNumber: 1,
          pageSize: 10,
          total: 0,
          totalPages: 0,
        },
        orderBy: 'Latest',
        search: '',
      },
    }),
    {} as { [K in AdminAssignmentsType]: AssignmentsState }
  ),
};

const adminAssignmentsSlice = createSlice({
  name: 'adminAssignments',
  initialState,
  reducers: {
    // Form
    setForm(state, { payload }: PayloadAction<Partial<AdminAssignmentsForm>>) {
      state.form = { ...state.form, ...payload };
    },
    resetForm(state) {
      state.form = initialState.form;
    },
    setPreferredQualification(
      state,
      { payload }: PayloadAction<IPreferredQualifications>
    ) {
      const qualifications = state.form.preferred_qualifications;

      if (payload === 'No preference') {
        state.form.preferred_qualifications = ['No preference'];
      } else {
        state.form.preferred_qualifications = qualifications.includes(payload)
          ? qualifications.filter(
              qualification =>
                qualification !== payload && qualification !== 'No preference'
            )
          : qualifications
              .filter(qualification => qualification !== 'No preference')
              .concat(payload);
      }
    },

    // Assignments
    setAssignments(
      state,
      {
        payload: [assignmentsType, { assignments, pagination }],
      }: PayloadAction<
        [
          AdminAssignmentsType,
          Pick<AssignmentsState, 'assignments' | 'pagination'>
        ]
      >
    ) {
      const assignmentsState = state[assignmentsType];

      state[assignmentsType] = {
        current: assignmentsState.current,
        proposals: assignmentsState.proposals,
        assignments,
        pagination,
        search: assignmentsState.search,
        orderBy: assignmentsState.orderBy,
      };
    },
    setCurrentAssignment(
      state,
      {
        payload: [key, value],
      }: PayloadAction<[AdminAssignmentsType, AdminAssignment | null]>
    ) {
      state[key].current = value;
    },
    setCurrentAssignmentProposals(
      state,
      {
        payload: [key, value],
      }: PayloadAction<[AdminAssignmentsType, AdminAssignmentProposal[]]>
    ) {
      if (state[key].current) {
        state[key].proposals = value;
      }
    },
    setPagination(
      state,
      {
        payload: [key, value],
      }: PayloadAction<[AdminAssignmentsType, Partial<Pagination>]>
    ) {
      state[key].pagination = { ...state[key].pagination, ...value };
    },

    setOrderBy(
      state,
      {
        payload: [key, value],
      }: PayloadAction<[AdminAssignmentsType, AdminAssignmentsOrder]>
    ) {
      state[key].orderBy = value;
    },

    setSearch(
      state,
      { payload: [key, value] }: PayloadAction<[AdminAssignmentsType, string]>
    ) {
      state[key].search = value;
    },
    setJobReviewCandidates(
      state,
      { payload }: PayloadAction<IReviewCandidatesResult>
    ) {
      state.jobReviewCandidatesAdmin = payload;
    },
    setJobReviewCandidatesAdminLoading(
      state,
      { payload }: PayloadAction<boolean>
    ) {
      state.jobReviewCandidatesAdminLoading = payload;
    },
    setEmptyAdminJobReviewCandidates(state) {
      state.jobReviewCandidatesAdmin = initialState.jobReviewCandidatesAdmin;
    },
  },
});

export const {
  setForm,
  resetForm,
  setPreferredQualification,
  setPagination,
  setOrderBy,
  setSearch,
  setAssignments,
  setCurrentAssignment,
  setCurrentAssignmentProposals,
  setJobReviewCandidatesAdminLoading,
  setJobReviewCandidates,
  setEmptyAdminJobReviewCandidates,
  // setPendingAssignments,
  // setCurrentPendingAssignment,
} = adminAssignmentsSlice.actions;

export default adminAssignmentsSlice.reducer;
