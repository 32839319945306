import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IInvoicesData } from '../../../core/models/models';

interface UserInvoicesState {
  data: IInvoicesData;
  isLoading: boolean;
  error: string;
}

const initialState: UserInvoicesState = {
  data: { pageNumber: 1, pageSize: 10, totalPages: 1, total: 1, results: [] },
  isLoading: false,
  error: '',
};

export const UserInvoicesSlice = createSlice({
  name: 'employer/invoices',
  initialState,
  reducers: {
    setEmployerInvoices(state, { payload }: PayloadAction<IInvoicesData>) {
      state.data = payload;
    },
  },
});

export const { setEmployerInvoices } = UserInvoicesSlice.actions;

export default UserInvoicesSlice.reducer;
