import React, { FC } from 'react';
import './UIButton.scss';
import Icon from '../UIIcon/UIIcon';
import { CircularProgress } from '@mui/material';

type UIButtonProps = {
  title: string;
  isLoading?: boolean;
  classModificator?: string;
  callback?: () => any;
  disabled?: boolean;
  type?: 'submit' | 'button' | 'reset';
};

const UIButton: FC<UIButtonProps> = ({
  title,
  isLoading = false,
  classModificator = '',
  callback,
  disabled,
  type = 'button',
}) => {
  return (
    <button
      type={type}
      className={`Button ${classModificator}`}
      onClick={callback ? event => callback() : undefined}
      disabled={isLoading || disabled}
    >
      {!isLoading ? (
        <>
          {title}
          {title === 'Bump' && (
            <Icon name={'bumpIcon'} className={'btn_bump'} />
          )}
        </>
      ) : (
        <CircularProgress size={20} sx={{ marginTop: '5px' }} />
      )}
    </button>
  );
};

export default UIButton;
