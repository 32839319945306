import React, { FC, useEffect } from 'react';
import styles from './_styles.module.scss';
import UIIcon from '../UIIcon/UIIcon';

interface UISelectCheckboxProps {
  title: string;
  id: string;
  checked?: boolean;
  defaultChecked?: boolean;
  type?: 'checkbox' | 'radio';
  name?: string;
  className?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const UISelectCheckbox: FC<UISelectCheckboxProps> = ({
  title,
  id,
  type = 'checkbox',
  name,
  className,
  checked,
  onChange,
  ...rest
}) => {
  useEffect(() => {}, [checked]);

  return (
    <div key={id}>
      <input
        type={type}
        className={styles.hidden_input}
        key={title}
        id={id}
        value={title}
        name={name}
        checked={checked}
        onChange={event => onChange(event)}
      />
      <label
        className={`${styles.label} ${className ? className : ''}`}
        htmlFor={id}
      >
        <UIIcon name={'check'} className={styles.icon} />
        {title}
      </label>
    </div>
  );
};

export default UISelectCheckbox;
