import React, { FunctionComponent } from 'react';
import './CandidateHeader.scss';
import { mobileLandscapeBreakpoint } from '../../../core/constants/variables';
import BurgerMenu from '../../BurgerMenu/BurgerMenu';
import HeaderCandidateNavLinks from '../HeaderCandidateNavLinks/HeaderCandidateNavLinks';
import { useWindowSize } from '../../../core/hooks/useWindowSize';
import Logo from '../../Logo/Logo';

export const CandidateHeader: FunctionComponent = () => {
  const size = useWindowSize();

  return (
    <div className='header__wrap'>
      <header className='header'>
        <Logo goTo={'/'} className={'logo-main'} />

        {size.width < mobileLandscapeBreakpoint ? (
          <BurgerMenu routes={'candidate'} />
        ) : (
          <HeaderCandidateNavLinks />
        )}
      </header>
    </div>
  );
};
