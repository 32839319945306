import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import cn from 'classnames';
import './UIMultipleSelect.scss';
import useOnClickOutside from '../../../core/hooks/useOnClickOutside';
import UISelectCheckbox from '../UISelectCheckbox/UISelectCheckbox';
import {
  ChosenFilters,
  setAllFilters,
} from '../../../redux/reducers/api/CandidateAssignmentsSlice';
import { useAppDispatch, useAppSelector } from '../../../core/hooks/reduxHooks';

type UISelectProps = {
  possibleList: string[] | number[];
  activeList: string[] | number[];
  heading: string;
  placeholder?: string;
  iconType?: 'industries' | 'location' | 'jobs' | 'education';
  activeListName?: ChosenFilters | 'preferred_qualifications';
  role?: 'candidate' | 'employer' | 'admin';
  onChangeList: (
    event: React.ChangeEvent<HTMLInputElement>,
    option: any
  ) => void;
  required?: boolean;
  className?: string;
  error?: boolean;
  isAssignmentFilters?: boolean;
  allTypesTitle?: string;
  allTypesChecked?: boolean;
  onAllTypesChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  allTypesId?: 'allRegions' | 'allCategories' | 'allJobTypes';
};

export const UIMultipleSelect = ({
  possibleList,
  activeList,
  placeholder,
  heading,
  iconType,
  onChangeList,
  required,
  error,
  className,
  allTypesTitle,
  isAssignmentFilters,
  onAllTypesChange,
  allTypesChecked,
  allTypesId,
}: UISelectProps) => {
  const dispatch = useAppDispatch();
  const [isListOpen, setListOpen] = useState<boolean>(false);
  const selectBoxRef = useRef<HTMLDivElement>(null);

  const closeDropdown = useCallback(() => setListOpen(false), []);

  useOnClickOutside(selectBoxRef, closeDropdown);

  const toggleList = useCallback(() => {
    setListOpen(!isListOpen);
  }, [isListOpen]);

  // const handleSort = useCallback(
  //   (e: React.MouseEvent, option: string) => {
  //     // setSelectedOption(option);
  //     // setListOpen(false);
  //   },
  //   [history, heading]
  // );

  useEffect(() => {
    if (
      allTypesChecked &&
      activeList.length !== possibleList.length &&
      allTypesId
    ) {
      dispatch(setAllFilters({ id: allTypesId, value: false }));
    }
  }, [allTypesChecked, activeList, possibleList]);

  return (
    <div
      className={`dropdown${className ? ` ${className}` : ''}`}
      ref={selectBoxRef}
    >
      <p
        className='dropdown__heading'
        style={{ color: error ? '#e90000' : '#35435e' }}
      >
        {heading}
        {required && (
          <span
            style={{
              color: error ? '#e90000' : '#35435e',
            }}
          >
            *
          </span>
        )}
      </p>
      <div className='dropdown__wrapper'>
        <button
          type='button'
          className='dropdown__header'
          onClick={() => toggleList()}
        >
          <span
            className={`dropdown__text`}
            style={{ opacity: !activeList.length ? 0.3 : 1 }}
          >
            {activeList.length ? activeList.join(', ') : placeholder}
          </span>
          <div className={'dropdown__icons'}>
            <span
              className={cn({
                dropdown__arrow: true,
                'dropdown__arrow--up': isListOpen,
              })}
            />
            {iconType && (
              <div
                className={cn({
                  select__icon: true,
                  'select__icon--jobs': iconType === 'jobs',
                  'select__icon--location': iconType === 'location',
                  'select__icon--industries': iconType === 'industries',
                  'select__icon--education': iconType === 'education',
                })}
              />
            )}
          </div>
        </button>
        <ul
          className={cn({
            dropdown__list: true,
            'dropdown__list--is-open': isListOpen,
          })}
        >
          {isAssignmentFilters && allTypesTitle && onAllTypesChange && (
            <UISelectCheckbox
              key={allTypesTitle}
              title={allTypesTitle}
              id={allTypesTitle}
              onChange={event => onAllTypesChange(event)}
              checked={allTypesChecked}
            />
          )}
          {possibleList.map(option => (
            <UISelectCheckbox
              key={option}
              id={`${option}`}
              title={`${option}`}
              checked={activeList.includes(option as never)}
              onChange={event => onChangeList(event, option)}
            />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default UIMultipleSelect;
