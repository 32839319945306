import React, { FC } from 'react';
import UIButtonLink from '../../UI/UIButtonLink/UIButtonLink';
import { useAppDispatch, useAppSelector } from '../../../core/hooks/reduxHooks';
import { openModal } from '../../../redux/reducers/ui/ModalDialogSlice';
import { drawerSlice } from '../../../redux/reducers/ui/DrawerSlice';
import './HeaderCandidateNavLinks.scss';
import UINavLink from '../../UI/UINavLink/UINavLink';
import Logout from '../../Logout/Logout';
import { useNavigate } from 'react-router-dom';
import {
  setFullTimeFilter,
  setPartTimeFilter,
  setSearchParamsJobTypesFilter,
} from '../../../redux/reducers/api/CandidateAssignmentsSlice';
import UIJobsTypeLink from '../../UI/UIJobsTypeLink/UIJobsTypeLink';
import useWebContent from '../../../core/hooks/useWebContent';

const HeaderCandidateNavLinks: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { token } = useAppSelector(state => state.userTokenReducer);
  const { job_types } = useAppSelector(
    state => state.candidateAssignments.activeFilters
  );
  const [webContent] = useWebContent();

  const { closeDrawer } = drawerSlice.actions;

  const noAuthUser = () => {
    return (
      <ul className='header__nav--main'>
        <li>
          <UIButtonLink
            title={webContent.go_to_job_poster_page_web}
            callback={() => {
              navigate('/employer', { replace: true });
            }}
            classModificator={
              'Button__link--for-employers Button__link--startIcon'
            }
          />
        </li>
        <li>
          <UIButtonLink
            title={'Login / Register'}
            callback={() => {
              dispatch(openModal('login'));
              dispatch(closeDrawer());
            }}
            classModificator={'Button__link--login'}
          />
        </li>
      </ul>
    );
  };

  const authUser = () => {
    return (
      <ul className='header__nav--main-auth'>
        <li>
          <UINavLink
            title={'My jobs'}
            icon='myJobs'
            to={'/search'}
            role={'candidate'}
          />
        </li>
        <li>
          <UINavLink
            title={'Profile'}
            icon='myProfile'
            to={'/profile'}
            role={'candidate'}
          />
        </li>
        <li>
          <UIButtonLink
            title={webContent.go_to_job_poster_page_web}
            callback={() => {
              navigate('/employer', { replace: true });
            }}
            classModificator={
              'Button__link--for-employers Button__link--startIcon'
            }
          />
        </li>
        <li>
          <Logout forUser={'candidate'} />
        </li>
      </ul>
    );
  };

  return (
    <div className='header__nav'>
      <ul className='header__nav--jobs-category'>
        <li>
          <UIJobsTypeLink
            goTo={`/search`}
            title='Part Time Jobs'
            jobType={'Part-time'}
            callback={() => {
              dispatch(setSearchParamsJobTypesFilter(['Part-time']));
            }}
          />
        </li>
        <li>
          <UIJobsTypeLink
            goTo={`/search`}
            title='Full Time Jobs'
            jobType={'Full-time'}
            callback={() => {
              dispatch(setSearchParamsJobTypesFilter(['Full-time']));
            }}
          />
        </li>
      </ul>

      {token ? authUser() : noAuthUser()}
    </div>
  );
};

export default HeaderCandidateNavLinks;
