import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import styles from './_styles.module.scss';
import { Link } from 'react-router-dom';
import { IAvailableSubscription } from '../../../core/models/models';
import UISelectSubscription from '../../UI/UISelect/UISelectSubscription';
import { setSelectedUserSubscription } from '../../../redux/reducers/api/PostUserJobSlice';
import { useAppDispatch, useAppSelector } from '../../../core/hooks/reduxHooks';

interface IAccountStatus {
  subscriptions: {
    basic_subscriptions: IAvailableSubscription[] | [];
    premium_subscriptions: IAvailableSubscription[] | [];
  };
  selectedSubscription: IAvailableSubscription;
  setSelectedSubscription: React.Dispatch<
    React.SetStateAction<IAvailableSubscription>
  >;
}

const AccountStatus: FC<IAccountStatus> = ({
  subscriptions,
  selectedSubscription,
  setSelectedSubscription,
}) => {
  const [isSubscriptionList, setSubscriptionList] = useState<
    IAvailableSubscription[]
  >([]);
  const dispatch = useAppDispatch();
  const { post } = useAppSelector(state => state.postUserJobReducer);

  useEffect(() => {
    setSubscriptionList([
      ...subscriptions.basic_subscriptions,
      ...subscriptions.premium_subscriptions,
    ]);
    if (
      isSubscriptionList[0] ||
      subscriptions.basic_subscriptions[0] ||
      subscriptions.premium_subscriptions[0]
    ) {
      setSelectedSubscription(
        isSubscriptionList[0] ||
          subscriptions.basic_subscriptions[0] ||
          subscriptions.premium_subscriptions[0]
      );
    }
  }, [
    subscriptions.basic_subscriptions.length,
    subscriptions.premium_subscriptions.length,
  ]);

  return (
    <div className={styles.status}>
      {isSubscriptionList.length > 0 ? (
        <div className={styles.dropdwn}>
          <UISelectSubscription
            className={styles.dropdwn_select}
            heading={'Please select the subscription:'}
            list={isSubscriptionList}
            selectedOption={selectedSubscription}
            setSelectedOption={option => {
              dispatch(setSelectedUserSubscription(option.subscription_id));
              setSelectedSubscription(option);
            }}
          />
        </div>
      ) : (
        <div className={styles.zero}>0 credits</div>
      )}

      <Link to='/employer/price' className={styles.button_purchase}>
        {isSubscriptionList.length > 0
          ? 'Purchase posting credits'
          : 'Purchase credit to post a job'}
      </Link>
    </div>
  );
};

export default AccountStatus;
