import React, { FC, useCallback, useEffect } from 'react';
import styles from './_styles.module.scss';
import { useAppDispatch, useAppSelector } from '../../core/hooks/reduxHooks';
import UIGoBack from '../../components/UI/UIGoBack/UIGoBack';
import UISaveFlag from '../../components/UI/UISaveFlag/UISaveFlag';
import JobHeading from '../../components/Candidate/JobDetails/JobHeading';
import JobCardDetails from '../../components/Candidate/JobDetails/JobCardDetails/JobCardDetails';
import JobTypeAndDate from '../../components/JobTypeAndDate/JobTypeAndDate';
import { Navigate, useParams } from 'react-router-dom';
import { setLoading } from '../../redux/reducers/ui/HandlerSlice';
import { getAssignmentCandidateJobDetails } from '../../core/services/axios';
import JobDetailsQualification from '../../components/Candidate/JobDetails/JobDetailsQualification';
import JobDetailsDescription from '../../components/Candidate/JobDetails/JobDetailsDescription';
import UIBackBtn from '../../components/UI/UIBackBtn/UIBackBtn';
import UIButton from '../../components/UI/UIButton/UIButton';
import {
  openApplyingModal,
  openModal,
} from '../../redux/reducers/ui/ModalDialogSlice';
import {
  deleteSaveAssignment,
  saveAssignment,
} from '../../redux/thunk/assignments';
import { setSelectedJob } from '../../redux/reducers/api/CandidateAssignmentsSlice';
import Loader from '../../components/Loader/Loader';
import RequireCompleteProfile from '../../containers/CandidateLayout/RequireCompleteProfile';
import { useWindowSize } from '../../core/hooks/useWindowSize';
import { breakpointForJobsList } from '../../core/constants/variables';
import JobDetailsWrap from '../../containers/JobDetailsWrap/JobDetailsWrap';

const CandidateJobDetailsMob: FC = () => {
  const { selectedJob } = useAppSelector(state => state.candidateAssignments);

  const dispatch = useAppDispatch();
  const size = useWindowSize();

  const token = useAppSelector(state => state.userTokenReducer.token);

  const { id } = useParams();

  const handleSaveAssignment = useCallback((jobId: string, save: boolean) => {
    if (save) {
      dispatch(saveAssignment(jobId));
    } else {
      dispatch(deleteSaveAssignment(jobId));
    }
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(setLoading(true));
      try {
        getAssignmentCandidateJobDetails(id).then(res => {
          if (res) {
            dispatch(setSelectedJob(res));
          }
        });
        dispatch(setLoading(false));
      } catch (error) {
        dispatch(setLoading(false));
      }
    }
  }, [id]);

  useEffect(() => {}, [size.width]);

  if (size.width > breakpointForJobsList) {
    return <Navigate to={`/search?id=${id}`} replace={true} />;
  }

  return (
    <RequireCompleteProfile>
      <section className={styles.job_details}>
        <div className={styles.header}>
          <UIGoBack searchPage={true} />
          <h3>Job Details</h3>
          {token ? (
            <UISaveFlag
              saved={selectedJob?.saved || false}
              color={'#35435e'}
              className={styles.flag}
              onClick={() => {
                return selectedJob
                  ? handleSaveAssignment(
                      selectedJob.assignment_id,
                      !selectedJob.saved
                    )
                  : null;
              }}
            />
          ) : (
            <div />
          )}
        </div>
        {selectedJob && selectedJob.assignment_id === id ? (
          <div className={styles.content}>
            <div className={styles.wrap}>
              <JobHeading heading={selectedJob.title} size={20} />
              <JobDetailsWrap
                // companyName={selectedJob.company_name}
                location={selectedJob.location}
                lowestRate={selectedJob.lowest_rate}
                highestRate={selectedJob.highest_rate}
                expectedSalaryDivider={selectedJob.expected_salary_divider}
                time={selectedJob.time}
              />
              <JobTypeAndDate
                date={selectedJob.created_at}
                type={selectedJob.job_type}
              />
            </div>
            <div className={styles.bottom_line} />
            <div className={styles.wrap}>
              <JobDetailsQualification
                industry={selectedJob.category}
                qualifications={selectedJob.preference}
                fullWidth={true}
              />
            </div>
            <div className={styles.bottom_line} />
            <div className={styles.wrap}>
              <JobDetailsDescription description={selectedJob.description} />
            </div>
            <div className={styles.bottom_line} />
            <div className={styles.wrap}>
              <div className={styles.buttons}>
                {/*<UIBackBtn searchPage={true} />*/}
                <UIButton
                  title={selectedJob.applied ? 'Applied' : 'Apply'}
                  disabled={selectedJob.applied}
                  callback={() =>
                    token
                      ? dispatch(openApplyingModal(selectedJob.assignment_id))
                      : dispatch(openModal('login'))
                  }
                />
              </div>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </section>
    </RequireCompleteProfile>
  );
};

export default CandidateJobDetailsMob;
