import React, { FC, useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../core/hooks/reduxHooks';
import { useNavigate } from 'react-router-dom';
import { drawerSlice } from '../../../redux/reducers/ui/DrawerSlice';
import { openModal } from '../../../redux/reducers/ui/ModalDialogSlice';
import styles from '../_styles.module.scss';
import UIButtonLink from '../../UI/UIButtonLink/UIButtonLink';
import UIDrawerNavLink from '../../UI/UIDrawerLink/UIDrawerLink';
import { IconType } from '../../UI/UIIcon/UIIcon';
import Logout from '../../Logout/Logout';
import useWebContent from '../../../core/hooks/useWebContent';

type EmployerNavListTypes = {
  title:
    | 'Price'
    | 'Post a job'
    | 'Manage jobs'
    | 'Profile'
    | 'Purchase history';
  path:
    | '/employer/price'
    | '/employer/post-jobs'
    | '/employer/manage-jobs'
    | '/employer/profile'
    | '/employer/purchase-history';
  icon: IconType;
  end?: boolean;
};

const EmployerNavList: EmployerNavListTypes[] = [
  {
    title: 'Post a job',
    path: '/employer/post-jobs',
    icon: 'postJob',
  },
  {
    title: 'Manage jobs',
    path: '/employer/manage-jobs',
    icon: 'manage',
  },
  {
    title: 'Profile',
    path: '/employer/profile',
    icon: 'myProfile',
  },
  {
    title: 'Price',
    path: '/employer/price',
    icon: 'shop',
  },
  {
    title: 'Purchase history',
    path: '/employer/purchase-history',
    icon: 'purchaseHistory',
  },
];

const EmployerDrawer: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const token = useAppSelector(state => state.userTokenReducer.token);
  const [webContent] = useWebContent();

  const { closeDrawer } = drawerSlice.actions;

  useEffect(() => {}, [token]);

  const DrawerEmployerContent = useCallback(() => {
    return (
      <>
        <ul className={styles.employer_list}>
          {EmployerNavList.map(({ title, path, icon }) => (
            <li
              key={icon}
              onClick={() => {
                dispatch(closeDrawer());
              }}
            >
              <UIDrawerNavLink
                title={title}
                goTo={path}
                icon={icon}
                className={styles.employer_nav_link}
                activeClassName={styles.employer_nav_link_active}
              />
            </li>
          ))}
        </ul>

        <ul className={styles.down_list}>
          <li className={styles.employer_link}>
            <UIButtonLink
              title={webContent.go_to_candidate_page_web}
              classModificator={styles.seeker_btn}
              callback={() => {
                navigate('/');
                dispatch(closeDrawer());
              }}
              icon='jobSeeker'
              iconDirection='left'
            />
          </li>
          <li>
            <Logout forUser={'candidate'} />
          </li>
        </ul>
      </>
    );
  }, []);

  const DrawerNotAuthEmployerContent = useCallback(() => {
    return (
      <ul className={styles.employer_list_no_auth}>
        <li
          className={styles.employer_link}
          onClick={() => dispatch(closeDrawer())}
        >
          <UIDrawerNavLink
            title={'Price'}
            goTo={'employer/price'}
            icon='price'
            className={styles.employer_nav_link_price}
          />
        </li>

        <li
          className={styles.employer_link}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <UIButtonLink
            title={webContent.go_to_candidate_page_web}
            classModificator={styles.seeker_btn}
            callback={() => {
              navigate('/');
              dispatch(closeDrawer());
            }}
            icon='jobSeeker'
            iconDirection='left'
          />
          <UIButtonLink
            title={'Login / Register'}
            callback={() => {
              dispatch(openModal('login'));
              dispatch(closeDrawer());
            }}
            classModificator={'Button__link--login'}
          />
        </li>
      </ul>
    );
  }, []);

  return (
    <div className={`${styles.drawer} ${styles.employer}`}>
      {token ? DrawerEmployerContent() : DrawerNotAuthEmployerContent()}
    </div>
  );
};

export default EmployerDrawer;
