import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IAssignmentDetails,
  IReviewCandidate,
  IReviewCandidatesResult,
  IUserManageJobs,
} from '../../../core/models/models';

interface UserManageJobsSliceState {
  jobs: IUserManageJobs;
  jobDetails: IAssignmentDetails | undefined;
  jobReviewCandidates: IReviewCandidatesResult;
  isLoadingCandidates: boolean;
  isLoadingManageJobs: boolean;
  order_by:
    | 'Latest'
    | 'Expired'
    | 'Full-time'
    | 'Part-time'
    | 'Internship'
    | 'Contract';
  isLoading: boolean;
}

const initialState: UserManageJobsSliceState = {
  jobs: { pageNumber: 1, pageSize: 6, totalPages: 1, total: 1, results: [] },
  jobDetails: undefined,
  jobReviewCandidates: {
    pageNumber: 1,
    pageSize: 6,
    totalPages: 1,
    total: 1,
    results: [],
  },
  isLoadingCandidates: false,
  isLoadingManageJobs: false,
  order_by: 'Latest',
  isLoading: false,
};

export const UserManageJobsSlice = createSlice({
  name: 'employer/manage_jobs',
  initialState,
  reducers: {
    setDetailsLoading(state, { payload }: PayloadAction<boolean>) {
      state.isLoading = payload;
    },
    setManageJobsLoading(state, { payload }: PayloadAction<boolean>) {
      state.isLoadingManageJobs = payload;
    },
    setJobDetails(state, { payload }: PayloadAction<IAssignmentDetails>) {
      state.jobDetails = payload;
    },
    setManageAssignmentPosts(
      state,
      { payload }: PayloadAction<IUserManageJobs>
    ) {
      state.jobs = payload;
    },
    repostAssignment(state) {
      if (state.jobDetails && state.jobDetails.bumps !== 0) {
        state.jobDetails.bumps = state.jobDetails.bumps - 1;
      }
    },
    setJobReviewCandidates(
      state,
      { payload }: PayloadAction<IReviewCandidatesResult>
    ) {
      state.jobReviewCandidates = payload;
    },
    setJobReviewCandidatesLoading(state, { payload }: PayloadAction<boolean>) {
      state.isLoadingCandidates = payload;
    },
    setEmptyJobReviewCandidates(state) {
      state.jobReviewCandidates = initialState.jobReviewCandidates;
    },
    setReviewCandidateInfo(
      state,
      { payload }: PayloadAction<{ id: string; data: IReviewCandidate }>
    ) {
      if (state.jobDetails) {
        state.jobDetails.shortlisted = state.jobDetails.shortlisted + 1;
        state.jobDetails.shortlists_left = state.jobDetails.shortlists_left - 1;
      }

      state.jobReviewCandidates.results = state.jobReviewCandidates.results.map(
        proposal => {
          if (proposal.id === payload.id) {
            return payload.data;
          } else {
            return proposal;
          }
        }
      );
    },
  },
});

export const {
  setDetailsLoading,
  setManageJobsLoading,
  setJobDetails,
  setManageAssignmentPosts,
  repostAssignment,
  setJobReviewCandidates,
  setJobReviewCandidatesLoading,
  setEmptyJobReviewCandidates,
  setReviewCandidateInfo,
} = UserManageJobsSlice.actions;

export default UserManageJobsSlice.reducer;
