import axios from 'axios';
import { AdminAssignmentsForm } from '../../redux/reducers/api/AdminAssignmentSlice';
import {
  IAssignmentDetails,
  IPaymentIntent,
  IReviewCandidatesResult,
  IUserLogin,
  IUserToken,
  AdminInvoicesParams,
  AdminInvoicesResponse,
  AddAdminAssignmentPayload,
  PostUserJobInformation,
  IAvailableWorkDetails,
  AdminAssignmentsParams,
  AdminAssignmentsResponse,
  AdminAssignment,
  AdminAssignmentsType,
  AdminAssignmentProposalsParams,
  AdminAssignmentProposalsResponse,
  AddAdminAssignmentResponse,
  ApproveAdminAssignmentResponse,
  RejectAdminAssignmentResponse,
  CreateAdminInvoicePayload,
  // IApplyJobPost,
  // PostUserJobPoster,
} from '../models/models';
import { getAssignmentJobReviewCandidateInfo } from '../../redux/thunk/employerAssignments';
import { log } from 'util';
// import { createAsyncThunk } from '@reduxjs/toolkit';

const { REACT_APP_BASE_API_URL, REACT_APP_BASE_PAYMENT_API_URL } = process.env;

export const apiClient = axios.create({
  baseURL: REACT_APP_BASE_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});
export const apiClientPaymentIntent = axios.create({
  baseURL: REACT_APP_BASE_PAYMENT_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

// export const getAnswersForOtherDetails = async () => {
//   try {
//     const response = await apiClient.get<{
//       price_page_shortlist: string;
//       price_page_bump: string;
//     }>('/subscription/other_info');
//
//     // console.log(response);
//
//     return response.data;
//   } catch (e) {
//     // console.log(e);
//   }
// };

export const setCreatePaymentIntent = async (data: IPaymentIntent) => {
  try {
    const response = await apiClientPaymentIntent.post<{
      clientSecret: string;
    }>('/stripe/create_payment_intent', data);

    // console.log(response);

    return response.data.clientSecret;
  } catch (e) {
    // console.log(e);
  }
};

export const getAssignmentJobDetailsAxios = async (assignment_id: string) => {
  try {
    const response = await apiClient.get<IAssignmentDetails>(
      `/assignments?assignment_id=${assignment_id}`
    );

    // console.log(response);

    return response.data;
  } catch (e) {
    // console.log(e);
  }
};

export const getAssignmentCandidateJobDetails = async (
  assignment_id: string
) => {
  try {
    const response = await apiClient.get<IAvailableWorkDetails>(
      `/assignments?assignment_id=${assignment_id}`
    );

    // console.log(response);

    return response.data;
  } catch (e) {
    // console.log(e);
  }
};

export const getInvoicePdfDetails = async (invoice_id: string) => {
  try {
    return await apiClient
      .get(`/invoices/${invoice_id}`, {
        responseType: 'arraybuffer',
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', `${invoice_id}.pdf`); //or any other extension
        document.body.appendChild(link);
        link.click();

        //clear the link after download
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      });
  } catch (e) {
    // console.log(e);
  }
};
