import React, { FC } from 'react';
import './WorkCardMob.scss';
import UIButton from '../../UI/UIButton/UIButton';
import JobCardType from '../../Candidate/JobDetails/JobCardDetails/JobCardType';
import { IAvailableWorkDetails } from '../../../core/models/models';
import { useAppDispatch, useAppSelector } from '../../../core/hooks/reduxHooks';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { setSelectedJob } from '../../../redux/reducers/api/CandidateAssignmentsSlice';
import {
  openApplyingModal,
  openModal,
} from '../../../redux/reducers/ui/ModalDialogSlice';
import LocationWithLinks from '../../LocationWithLinks/LocationWithLinks';

type WorkCardDesktopProps = {
  work: IAvailableWorkDetails;
};

const WorkCardDesktop: FC<WorkCardDesktopProps> = ({ work }) => {
  const token = useAppSelector(state => state.userTokenReducer.token);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const rateMessage =
    work.lowest_rate === 0 && work.highest_rate === 0
      ? `${work.null_rate_message}`
      : work.lowest_rate === work.highest_rate
      ? `$${work.lowest_rate} / ${work.expected_salary_divider || 'month'}`
      : work.lowest_rate && work.highest_rate
      ? `$${work.lowest_rate}-${work.highest_rate} / ${
          work.expected_salary_divider || 'month'
        }`
      : work.lowest_rate || work.highest_rate
      ? `$${work.lowest_rate || work.highest_rate} / ${
          work.expected_salary_divider || 'month'
        }`
      : '-';

  return (
    <div className='work__card-desk card-desk'>
      <div className='card-desk__info'>
        <p className='new-job'>New</p>
        <h3 className='card-desk__title'>{work.title}</h3>
        <div className='card-desk__price'>{rateMessage}</div>
      </div>
      <div className='card-desk__interaction'>
        <div className={'interaction__wrap'}>
          <JobCardType type={work.job_type} />
          <div className='card-desk__location'>
            <div className='card-desk__location--icon info__icon' />
            {work.location_links ? (
              <LocationWithLinks
                location={work.location}
                links={work.location_links}
              />
            ) : (
              work.location
            )}
          </div>
        </div>
        <div className='card-desk__buttons'>
          <UIButton
            title={'Details'}
            classModificator={'Button--details'}
            callback={() => {
              dispatch(setSelectedJob(work));
              navigate(`/search`);
            }}
          />
          <UIButton
            title={work.applied ? 'Applied' : 'Apply'}
            disabled={work.applied}
            classModificator={'--apply'}
            callback={() => {
              if (token) {
                dispatch(openApplyingModal(work.assignment_id));
              } else {
                dispatch(openModal('login'));
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default WorkCardDesktop;
