import React, { FC, useEffect } from 'react';
import './WorkCardList.scss';
import WorkCardMob from '../WorkCardMob/WorkCardMob';
import { Container } from '@mui/material';
import { useWindowSize } from '../../../core/hooks/useWindowSize';
import {
  laptopsBreakpoint,
  tabletsPortraitBreakpoint,
} from '../../../core/constants/variables';
import WorkCardDesktop from '../WorkCardMob/WorkCardDesktop';
import { useAppDispatch, useAppSelector } from '../../../core/hooks/reduxHooks';
import { getAssignmentsAsync } from '../../../redux/thunk/assignments';
import { Link, useSearchParams } from 'react-router-dom';
import UIButton from '../../UI/UIButton/UIButton';
import { useRouter } from '../../../core/hooks/useRouter';
import styles from '../../UI/UIJobsTypeLink/_styles.module.scss';
import UIPagination from '../../UI/UIPagination/UIPagination';
import {
  clearActiveFilters,
  setCurrentPage,
  setSearchParamsJobTypesFilter,
} from '../../../redux/reducers/api/CandidateAssignmentsSlice';
import search from '../../../containers/Search/Search';

const WorkCardList: FC = () => {
  const size = useWindowSize();
  const dispatch = useAppDispatch();
  const { navigate } = useRouter();

  const { assignments, totalPages, selectedPage } = useAppSelector(
    state => state.candidateAssignments
  );

  useEffect(() => {
    dispatch(getAssignmentsAsync({ page_size: 10 }));
  }, []);

  return (
    <Container
      maxWidth='lg'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '50px',
      }}
    >
      <div className='card__list'>
        {assignments?.map(work => {
          return size.width > tabletsPortraitBreakpoint ? (
            <WorkCardDesktop work={work} key={work.assignment_id} />
          ) : (
            <WorkCardMob work={work} key={work.assignment_id} />
          );
        })}
      </div>
      {totalPages > 1 && (
        <UIPagination
          currentPage={1}
          totalPages={totalPages}
          onChangePage={(event, page) => {
            dispatch(setCurrentPage(page));
            dispatch(setSearchParamsJobTypesFilter([]));
            dispatch(clearActiveFilters());
            navigate('search');
          }}
          size={'small'}
        />
      )}
    </Container>
  );
};

export default WorkCardList;
