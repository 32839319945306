import React, { FC, useEffect } from 'react';
import styles from './_styles.module.scss';
import UIModalDialogHeader from '../../UI/UIModalDialog/UIModalDialogHeader';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../core/hooks/reduxHooks';
import { getSubscriptionShortlistPackages } from '../../../redux/thunk/subscription';
import PriceCardBumpShortlist from '../../Employer/Prices/PriceCardBumpShortlist/PriceCardBumpShortlist';
import { setSelectedShortlistPackage } from '../../../redux/reducers/ui/SubscriptionPackagesSlice';
import UIButtonLink from '../../UI/UIButtonLink/UIButtonLink';
import { closeModal } from '../../../redux/reducers/ui/ModalDialogSlice';

const PurchaseShortlistAmount: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { shortlistPackages, selectedShortlistPackage } = useAppSelector(
    state => state.subscriptionPackagesReducer
  );

  useEffect(() => {
    dispatch(getSubscriptionShortlistPackages());
  }, []);

  if (!shortlistPackages || !selectedShortlistPackage) {
    return null;
  }

  return (
    <div className={styles.modal}>
      <UIModalDialogHeader heading={'Purchase Shortlist'} />
      <div className={styles.modal_content}>
        <div className={styles.modal_content_cards}>
          <PriceCardBumpShortlist
            id={selectedShortlistPackage.package_id}
            type={'shortlist'}
            price={selectedShortlistPackage.price}
            totalAmount={shortlistPackages.length}
            onSelectPackage={amount =>
              dispatch(setSelectedShortlistPackage({ amount }))
            }
          />
        </div>
        <div className={styles.purchase_amount}>
          <div className={styles.line} />
          <div className={styles.total_amount}>
            Total: ${selectedShortlistPackage.price}
          </div>
          <UIButtonLink
            callback={() => {
              dispatch(closeModal());
              if (selectedShortlistPackage.price !== 0) {
                navigate(
                  `/employer/price/purchase/bump_or_shortlist/${selectedShortlistPackage.package_id}`
                );
              }
            }}
            title={'Purchase'}
            classModificator={styles.purchase_btn}
          />
        </div>
      </div>
    </div>
  );
};

export default PurchaseShortlistAmount;
