import {
  IUserLogin,
  IUserRegister,
  IUserVerify,
  VerifyNumberPayload,
} from '../../core/models/models';
import { AppDispatch } from '../store';
import { setLoading } from '../reducers/ui/HandlerSlice';
import { API } from '../../core/api/api';
import {
  AuthTokenApi,
  setTokensToCookies,
} from '../../core/utils/autrhTokenApi';
import {
  setAdminRole,
  setError,
  setPasswordTemp,
  setRefreshToken,
  setToken,
  setUserPhoneNumber,
} from '../reducers/api/UserTokenSlice';
import {
  closeModal,
  openErrorModal,
  openInfoModal,
  openModal,
  setOtpTimestamp,
} from '../reducers/ui/ModalDialogSlice';
import { AxiosError } from 'axios';
import { getProfileStatus } from './profile';

export const userLogin = (payload: IUserLogin) => (dispatch: AppDispatch) => {
  dispatch(setLoading(true));

  return API.userLogin(payload)
    .then(response => {
      dispatch(setError(''));
      dispatch(setOtpTimestamp(response.data.otp_expiration_timestamp));

      if (response.data.is_admin) {
        dispatch(openModal('verifyAdminLogin'));
      } else {
        setTokensToCookies(
          response.data.access_token,
          response.data.refresh_token
        );
        dispatch(setToken(response.data.access_token));
        dispatch(setRefreshToken(response.data.refresh_token));

        AuthTokenApi(response.data.access_token);
        dispatch(getProfileStatus());
        dispatch(closeModal());
      }
    })
    .catch(err => {
      // console.log(err);
      dispatch(setError(err.response.data.message));
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
};

export const userRegister =
  (payload: IUserRegister) => (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    return API.userRegister(payload)
      .then(response => {
        dispatch(setError(''));
        dispatch(setOtpTimestamp(response.data.otp_expiration_timestamp));
        dispatch(openModal('verifyRegistration'));
      })
      .catch(err => {
        dispatch(setError(err.response.data.message));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

export const verifyUserRegister =
  (payload: IUserVerify) => (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    return API.verifyUserRegister(payload)
      .then(response => {
        setTokensToCookies(
          response.data.access_token,
          response.data.refresh_token
        );
        AuthTokenApi(response.data.access_token);
        dispatch(setToken(response.data.access_token));
        dispatch(getProfileStatus());
        dispatch(setError(''));
        dispatch(setPasswordTemp(''));
        dispatch(setUserPhoneNumber(''));
        dispatch(closeModal());
      })
      .catch(err => {
        dispatch(setError(err.response.data.message));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

export const resendUserRegisterVerifyCode =
  (payload: { hp_number: string; password: string }) =>
  (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    return API.resendUserRegisterVerifyCode(payload)
      .then(response => {
        dispatch(setOtpTimestamp(response.data.otp_expiration_timestamp));
      })
      .catch(err => {
        dispatch(setError(err.response.data.message));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

export const verifyAdminLogin =
  (payload: VerifyNumberPayload) => (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    return API.verifyAdminLogin(payload)
      .then(response => {
        setTokensToCookies(
          response.data.access_token,
          response.data.refresh_token
        );
        AuthTokenApi(response.data.access_token);
        dispatch(setToken(response.data.access_token));
        dispatch(setAdminRole(true));
        dispatch(setPasswordTemp(''));
        dispatch(setUserPhoneNumber(''));
        dispatch(closeModal());
      })
      .catch((err: AxiosError<{ message: string }>) => {
        if (err.response?.data) {
          dispatch(setError(err.response.data.message as string));
        } else {
          dispatch(setError(err.message));
        }
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

export const resendAdminVerifyCode =
  (payload: { hp_number: string; password: string }) =>
  (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    return API.resendAdminVerifyCode(payload)
      .then(response => {
        dispatch(setOtpTimestamp(response.data.otp_expiration_timestamp));
      })
      .catch(err => {
        dispatch(setError(err.response.data.message));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

export const verifyResetLoginPasswordUpdate =
  (payload: { hp_number: string; password: string; code: string }) =>
  (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    return API.verifyResetLoginPasswordUpdate(payload)
      .then(res => {
        // dispatch(userInformationAsync());
        dispatch(setPasswordTemp(''));
        dispatch(setUserPhoneNumber(''));
        dispatch(
          openInfoModal({
            title: 'Success',
            message: 'Password changed successfully',
          })
        );
      })
      .catch(err => {
        if (err.response?.data) {
          dispatch(setError(err.response.data.message as string));
        } else {
          dispatch(setError(err.message));
        }
        // if (err.response) {
        //   if (typeof err.response.data.message === 'string') {
        //     dispatch(openErrorModal(modalContentTexts.error.text));
        //   } else {
        //     dispatch(openErrorModal(modalContentTexts.error.text));
        //   }
        // } else if (err.request) {
        //   dispatch(openErrorModal(modalContentTexts.error.text));
        // } else {
        //   dispatch(openErrorModal(modalContentTexts.error.text));
        // }
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

export const resetPasswordFromLogin =
  (payload: string) => (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    return API.resetPasswordFromLogin(payload)
      .then(response => {
        dispatch(setOtpTimestamp(response.data.otp_expiration_timestamp));
        dispatch(openModal('verifyLoginPasswordUpdate'));
      })
      .catch(err => {
        if (err.response) {
          if (typeof err.response.data.message === 'string') {
            dispatch(openErrorModal(err.response.data.message));
          } else {
            dispatch(openErrorModal(err.response.status));
          }
        } else if (err.request) {
          dispatch(openErrorModal(err.request.data || err.message));
        } else {
          dispatch(openErrorModal(err.message));
        }
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
