import React, { FC } from 'react';
import { openModal } from '../../../redux/reducers/ui/ModalDialogSlice';
import { useAppDispatch } from '../../../core/hooks/reduxHooks';

const UIForgotPass: FC = () => {
  const dispatch = useAppDispatch();

  return (
    <div
      onClick={() => dispatch(openModal('enterPhone'))}
      className='auth__forgot-pass'
    >
      <span>Forgot password?</span>
    </div>
  );
};

export default UIForgotPass;
