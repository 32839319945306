import React, { FC } from 'react';
import { useAppDispatch } from '../../../core/hooks/reduxHooks';
import styles from '../_styles.module.scss';
import UIModalDialogHeader from '../../UI/UIModalDialog/UIModalDialogHeader';
import useWebContent from '../../../core/hooks/useWebContent';
import { Link } from 'react-router-dom';
import { closeModal } from '../../../redux/reducers/ui/ModalDialogSlice';

const PurchaseMoreCredits: FC = () => {
  const dispatch = useAppDispatch();
  const [webContent] = useWebContent();

  return (
    <div className={`${styles.modal}`}>
      <UIModalDialogHeader heading={'Credits'} />
      <div className={styles.modal_body}>
        <p className={styles.message}>
          {webContent.no_more_posting_credits_modal}
        </p>
        <Link
          to={'/employer/price'}
          className={styles.purchase_credits}
          onClick={() => dispatch(closeModal())}
        >
          {webContent.purchase_posting_credits_button}
        </Link>
      </div>
    </div>
  );
};

export default PurchaseMoreCredits;
