import React, { FC, useEffect, useState } from 'react';
import styles from './_styles.module.scss';
import JobDetailsMobContainer from '../../../../containers/JobDetailsMobContainer/JobDetailsMobContainer';
import { Navigate, useParams } from 'react-router-dom';
import { setLoading } from '../../../../redux/reducers/ui/HandlerSlice';
import { getAssignmentJobDetailsAxios } from '../../../../core/services/axios';
import { IAssignmentDetails } from '../../../../core/models/models';
import JobHeading from '../../../Candidate/JobDetails/JobHeading';
import JobCardDetails from '../../../Candidate/JobDetails/JobCardDetails/JobCardDetails';
import JobTypeAndDate from '../../../JobTypeAndDate/JobTypeAndDate';
import ShortlistsAndBumps from '../ShortlistsAndBumps/ShortlistsAndBumps';
import JobDetailsQualification from '../../../Candidate/JobDetails/JobDetailsQualification';
import JobDetailsDescription from '../../../Candidate/JobDetails/JobDetailsDescription';
import ReviewCandidatesAccordion from '../ReviewCandidatesAccordion/ReviewCandidatesAccordion';
import ReviewCandidatesContent from '../ReviewCandidatesContent/ReviewCandidatesContent';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../core/hooks/reduxHooks';
import { getAssignmentJobDetails } from '../../../../redux/thunk/employerAssignments';
import { breakpointForJobsList } from '../../../../core/constants/variables';
import { useWindowSize } from '../../../../core/hooks/useWindowSize';
import Loader from '../../../Loader/Loader';
import JobDetailsWrap from '../../../../containers/JobDetailsWrap/JobDetailsWrap';

const ViewJobMobile: FC = () => {
  const dispatch = useAppDispatch();

  const size = useWindowSize();

  const { jobId } = useParams();
  const { jobDetails, isLoading } = useAppSelector(
    state => state.UserManageJobsReducer
  );

  useEffect(() => {
    if (jobId) {
      dispatch(getAssignmentJobDetails(jobId));
    }
  }, [jobId]);

  if (size.width > 1044) {
    return (
      <Navigate
        to={`/employer/manage-jobs?assignment_id=${jobId}`}
        replace={true}
      />
    );
  }

  if (isLoading) {
    return (
      <JobDetailsMobContainer>
        <section className={styles.details}>
          <Loader />
        </section>
      </JobDetailsMobContainer>
    );
  }

  if (jobDetails) {
    return (
      <JobDetailsMobContainer>
        <section className={styles.details}>
          <div className={styles.details_wrap}>
            <div className={styles.details_header}>
              <JobHeading heading={jobDetails.title} size={20} />
              <JobDetailsWrap
                companyName={jobDetails.company_name}
                location={jobDetails.location}
                lowestRate={jobDetails.lowest_rate}
                highestRate={jobDetails.highest_rate}
                expectedSalaryDivider={jobDetails.expected_salary_divider}
                time={jobDetails.time}
                locationLinks={jobDetails.location_links}
                fullDetails={true}
              />
              <JobTypeAndDate
                date={jobDetails.created_at}
                type={jobDetails.job_type}
              />
            </div>
          </div>
          <div className={styles.details_wrap}>
            <ShortlistsAndBumps
              assignmentId={jobDetails.assignment_id}
              bumps={jobDetails.bumps}
              shortlisted={jobDetails.shortlisted}
              shortlists_left={jobDetails.shortlists_left}
            />
          </div>
          <div className={styles.details_wrap}>
            <div className={styles.details_qualification}>
              <JobDetailsQualification
                industry={jobDetails.category}
                qualifications={jobDetails.preference}
              />
            </div>
          </div>
          <div className={styles.description}>
            <JobDetailsDescription description={jobDetails.description} />
          </div>

          <ReviewCandidatesAccordion>
            <ReviewCandidatesContent jobId={jobId} role={'employer'} />
          </ReviewCandidatesAccordion>
        </section>
      </JobDetailsMobContainer>
    );
  } else {
    return (
      <JobDetailsMobContainer>
        <section className={styles.details}></section>
      </JobDetailsMobContainer>
    );
  }
};

export default ViewJobMobile;
