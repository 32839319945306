import React, { FC } from 'react';
import { NavLink, useMatch } from 'react-router-dom';
import styles from './_styles.module.scss';

const UIButtonNavLink: FC<{
  goTo: string;
  title: string;
  className?: string;
  end?: boolean;
}> = ({ goTo, title, className, end }) => {
  const match = useMatch({
    path: goTo,
    end: end ?? goTo.length === 1,
  });

  return (
    <NavLink
      end={end}
      to={goTo}
      className={`${styles.custom_bt_link} ${match && styles.bt_link_active} ${
        className || ''
      }`}
    >
      {/* eslint-disable-next-line react/no-unknown-property */}
      <span about={title}>{title}</span>
    </NavLink>
  );
};

export default UIButtonNavLink;
