import React, { FC } from 'react';
import styles from '../JobsByIndustries/_styles.module.scss';
import { useAppDispatch } from '../../core/hooks/reduxHooks';
import { switchActiveFiltersItem } from '../../redux/reducers/api/CandidateAssignmentsSlice';
import { useRouter } from '../../core/hooks/useRouter';
import { JobCategoriesTypes } from '../../core/models/models';

interface SortIndustrieProps {
  industrie: JobCategoriesTypes;
  logo: any;
}

const SortIndustrie: FC<SortIndustrieProps> = ({ industrie, logo }) => {
  const dispatch = useAppDispatch();
  const { navigate } = useRouter();

  const searchByIndustrie = () => {
    dispatch(
      switchActiveFiltersItem({
        array: 'categories',
        value: industrie,
        add: true,
      })
    );
    navigate('search');
  };

  return (
    <div className={styles.industries_item} onClick={searchByIndustrie}>
      <div className={styles.circle_wrapper}>
        <div className={styles.bg_circle} />
        <img src={logo} alt={industrie} className={styles.industrie_img} />
      </div>
      <h2 className={styles.industrie_heading}>{industrie}</h2>
    </div>
  );
};

export default SortIndustrie;
