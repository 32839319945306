import React, { FC, useCallback, useEffect, useState } from 'react';
import styles from './_styles.module.scss';
import JobsInfoListContainer from '../../../containers/JobsInfoListContainer/JobsInfoListContainer';
import { useAppSelector, useAppDispatch } from '../../../core/hooks/reduxHooks';
import UIPagination from '../../UI/UIPagination/UIPagination';
import { useWindowSize } from '../../../core/hooks/useWindowSize';
import ManageJobMobCard from './ManageJobMobCard/ManageJobMobCard';
import { breakpointForJobsList } from '../../../core/constants/variables';
import UIFilterSelectBox from '../../UI/UIFilterSelectBox/UIFilterSelectBox';
import ManageJobsList from './ManageJobsList/ManageJobsList';
import ManageJobDetails from './ManageJobDetails/ManageJobDetails';
import { useSearchParams } from 'react-router-dom';
import { getManageAssignmentPosts } from '../../../redux/thunk/employerAssignments';
import { CircularProgress } from '@mui/material';

const orderByOptions = [
  'Latest',
  'Expired',
  'Full-time',
  'Part-time',
  'Internship',
  'Contract',
];

type IOrderBy =
  | 'Latest'
  | 'Expired'
  | 'Full-time'
  | 'Part-time'
  | 'Internship'
  | 'Contract';

const ManageJobs: FC = () => {
  const [isJobsPage, setJobsPage] = useState<number>(1);
  const [isOrderBy, setOrderBy] = useState<IOrderBy>('Latest');
  const [searchParams, setSearchParams] = useSearchParams();
  const assignmentId = searchParams.get('assignment_id');

  const { jobs, isLoadingManageJobs } = useAppSelector(
    state => state.UserManageJobsReducer
  );

  const dispatch = useAppDispatch();
  const size = useWindowSize();

  const changeSelectedJobId = useCallback((id: string) => {
    setSearchParams({ assignment_id: id });
  }, []);

  useEffect(() => {
    dispatch(
      getManageAssignmentPosts({
        order_by: isOrderBy,
        page_number: isJobsPage,
        page_size: 10,
      })
    );
  }, [isJobsPage, isOrderBy]);

  useEffect(() => {
    if (
      !assignmentId &&
      jobs.results.length > 0 &&
      size.width > breakpointForJobsList
    ) {
      changeSelectedJobId(jobs.results[0].assignment_id);
    }
  }, [jobs, assignmentId]);

  if (!jobs.results.length) {
    return (
      <section className={styles.manage_jobs}>
        {isLoadingManageJobs ? (
          <CircularProgress style={{ marginTop: '35px' }} />
        ) : (
          <h3
            style={{ margin: '80px', fontSize: '24px', textAlign: 'center' }}
          >{`Jobs not published yet`}</h3>
        )}
      </section>
    );
  }

  if (size.width < 1045) {
    return (
      <section className={styles.manage_jobs}>
        <div className={styles.mob_wrap}>
          <div className={styles.select}>
            <UIFilterSelectBox
              list={orderByOptions}
              selectedOption={isOrderBy}
              setSelectedOption={setOrderBy}
            />
          </div>
          <ul className={styles.mob_job_list}>
            {jobs.results.map(jobDetails => {
              return (
                <ManageJobMobCard
                  key={jobDetails.assignment_id}
                  job={jobDetails}
                />
              );
            })}
          </ul>
          <UIPagination
            currentPage={isJobsPage}
            totalPages={jobs.totalPages}
            onChangePage={(event, page) => {
              setJobsPage(page);
            }}
          />
        </div>
      </section>
    );
  } else if (assignmentId) {
    return (
      <section className={styles.manage_jobs}>
        <div className={styles.wrap}>
          <div className={styles.select}>
            {jobs && (
              <UIFilterSelectBox
                list={orderByOptions}
                selectedOption={isOrderBy}
                setSelectedOption={setOrderBy}
              />
            )}
          </div>
          <JobsInfoListContainer>
            <ManageJobsList
              jobsList={jobs.results}
              selectedJobId={assignmentId}
              onClick={changeSelectedJobId}
            />

            <ManageJobDetails assignmentId={assignmentId} />

            <UIPagination
              currentPage={isJobsPage}
              totalPages={jobs.totalPages}
              onChangePage={(event, page) => {
                setJobsPage(page);
              }}
            />
          </JobsInfoListContainer>
        </div>
      </section>
    );
  } else {
    return <section className={styles.manage_jobs}></section>;
  }
};

export default ManageJobs;
