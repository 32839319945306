import React, { FC } from 'react';
import styles from './_styles.module.scss';

interface WorkStepCardProps {
  title: string;
  logo: any;
  stepText: string;
}

const WorkStepCard: FC<WorkStepCardProps> = ({
  title,
  logo,
  stepText,
}) => {
  return (
    <div className={styles.step_card}>
      <h4 className={styles.title}>{title}</h4>
      <img className={styles.main_img} src={logo} />
      <p className={styles.text}>
        <span>{stepText}</span>
      </p>
    </div>
  );
};

export default WorkStepCard;
