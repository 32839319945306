import React, { FC, useEffect, useMemo, useState } from 'react';
import styles from './_styles.module.scss';
import UIModalDialogHeader from '../../UI/UIModalDialog/UIModalDialogHeader';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../core/hooks/reduxHooks';
import {
  getSubscriptionBumpPackages,
  getSubscriptionShortlistPackages,
} from '../../../redux/thunk/subscription';
import PriceCardBumpShortlist from '../../Employer/Prices/PriceCardBumpShortlist/PriceCardBumpShortlist';
import {
  setSelectedBumpPackage,
  setSelectedShortlistPackage,
} from '../../../redux/reducers/ui/SubscriptionPackagesSlice';
import UIButtonLink from '../../UI/UIButtonLink/UIButtonLink';
import { closeModal } from '../../../redux/reducers/ui/ModalDialogSlice';

const PurchaseBumpAmount: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { bumpPackages, selectedBumpPackage } = useAppSelector(
    state => state.subscriptionPackagesReducer
  );

  // const totalPackagesAmount = useMemo(() => {
  //   if (selectedBumpPackage && selectedShortlistPackage) {
  //     return selectedBumpPackage.price + selectedShortlistPackage.price;
  //   } else {
  //     return selectedBumpPackage?.price || selectedShortlistPackage?.price;
  //   }
  // }, [selectedBumpPackage, selectedShortlistPackage]);

  useEffect(() => {
    dispatch(getSubscriptionBumpPackages());
  }, []);

  if (!bumpPackages || !selectedBumpPackage) {
    return null;
  }

  return (
    <div className={styles.modal}>
      <UIModalDialogHeader heading={'Purchase Bump'} />
      <div className={styles.modal_content}>
        <div className={styles.modal_content_cards}>
          <PriceCardBumpShortlist
            id={selectedBumpPackage.package_id}
            type={'bump'}
            price={selectedBumpPackage.price}
            totalAmount={bumpPackages.length}
            onSelectPackage={amount =>
              dispatch(setSelectedBumpPackage({ amount }))
            }
          />
        </div>
        <div className={styles.purchase_amount}>
          {/*<div className={styles.totals}>*/}
          {/*  <div>*/}
          {/*    {selectedBumpPackage.amount} x Bump total = ${' '}*/}
          {/*    {selectedBumpPackage.price}*/}
          {/*  </div>*/}
          {/*  <div>*/}
          {/*    {' '}*/}
          {/*    {selectedShortlistPackage.amount} x Shortlist total = ${' '}*/}
          {/*    {selectedShortlistPackage.price}*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className={styles.line} />
          <div className={styles.total_amount}>
            Total: ${selectedBumpPackage.price}
          </div>
          <UIButtonLink
            callback={() => {
              dispatch(closeModal());
              if (selectedBumpPackage?.price !== 0) {
                navigate(
                  `/employer/price/purchase/bump_or_shortlist/${selectedBumpPackage.package_id}`
                );
              }
            }}
            title={'Purchase'}
            classModificator={styles.purchase_btn}
          />
        </div>
      </div>
    </div>
  );
};

export default PurchaseBumpAmount;
