import React, { FC, useCallback, useEffect, useState } from 'react';
import UIModalDialogHeader from '../../UI/UIModalDialog/UIModalDialogHeader';
import { useAppDispatch, useAppSelector } from '../../../core/hooks/reduxHooks';
import UIRegistrationInput from '../../UI/UIRegistration/UIRegistrationInput';
import './RegistrationModalContent.scss';
import UIButton from '../../UI/UIButton/UIButton';
import {
  setError,
  setPasswordTemp,
  setUserPhoneNumber,
} from '../../../redux/reducers/api/UserTokenSlice';
import { openModal } from '../../../redux/reducers/ui/ModalDialogSlice';
import { IUserRegister } from '../../../core/models/models';
import { emailRegex, phoneRegex } from '../../../core/constants/regex';
import { userRegister } from '../../../redux/thunk/login';

const errorDictionary = {
  empty: 'Please fill all fields',
  email: 'Please enter a valid email',
  passwordRepeat: 'Passwords do not match',
};

const RegistrationModalContent: FC = () => {
  const [isUserName, setUserName] = useState('');
  const [isUserEmail, setUserEmail] = useState('');
  const [isUserRepeatPass, setUserRepeatPass] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isUserNotify, setUserNotify] = useState({
    telegram: '',
    email: '',
  });
  const [chosenNotifications, setChosenNotifications] = useState<
    Array<'Telegram bot' | 'Email'>
  >([]);
  const [validError, setValidError] = useState('');

  const { hp_number, passwordTemp, error } = useAppSelector(
    state => state.userTokenReducer
  );

  const dispatch = useAppDispatch();

  const isValidEmail = (email: string) => {
    return emailRegex.test(email);
  };

  const handleRegister = useCallback(
    async event => {
      event.preventDefault();
      const payload: IUserRegister = {
        // name: isUserName,
        // email: isUserEmail,
        hp_number,
        password: passwordTemp,
      };

      if (chosenNotifications.length) {
        payload.chosen_notifications = chosenNotifications;
      }

      if (!hp_number.length || !passwordTemp.length) {
        setValidError('empty');
      } else if (passwordTemp !== isUserRepeatPass) {
        setValidError('passwordRepeat');
      } else {
        setValidError('');
        dispatch(userRegister(payload));
      }

      // else if (!isValidEmail(isUserEmail)) {
      //   setValidError('email');
      // } else
    },
    [
      isUserName,
      isUserEmail,
      hp_number,
      passwordTemp,
      isUserNotify,
      isUserRepeatPass,
      chosenNotifications,
    ]
  );

  useEffect(() => {
    setValidError('');

    dispatch(setError(''));
    dispatch(setPasswordTemp(''));
  }, []);

  return (
    <div className='modal__dialog'>
      <UIModalDialogHeader heading={'registration'} />
      <form onSubmit={handleRegister} className={'registration'}>
        {/*<UIRegistrationInput*/}
        {/*  id={'name'}*/}
        {/*  label={'Name'}*/}
        {/*  type='text'*/}
        {/*  value={isUserName}*/}
        {/*  error={validError === 'empty'}*/}
        {/*  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {*/}
        {/*    if (validError.length) {*/}
        {/*      setValidError('');*/}
        {/*    }*/}

        {/*    setUserName(event.target.value);*/}
        {/*  }}*/}
        {/*/>*/}
        {/*<UIRegistrationInput*/}
        {/*  id={'email'}*/}
        {/*  label={'Email'}*/}
        {/*  type='email'*/}
        {/*  value={isUserEmail}*/}
        {/*  error={validError === 'empty' || validError === 'email'}*/}
        {/*  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {*/}
        {/*    if (validError.length) {*/}
        {/*      setValidError('');*/}
        {/*    }*/}

        {/*    setUserEmail(event.target.value);*/}
        {/*  }}*/}
        {/*/>*/}
        <UIRegistrationInput
          id={'phone'}
          label={'Phone'}
          type='tel'
          value={hp_number}
          error={validError === 'empty'}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (validError.length) {
              setValidError('');
            }

            if (
              e.target.value.match(phoneRegex) &&
              e.target.value.length < 13
            ) {
              dispatch(setUserPhoneNumber(e.target.value));
            }
          }}
        />
        <UIRegistrationInput
          id={'password'}
          label={'Password'}
          type='password'
          value={passwordTemp}
          error={validError === 'empty' || validError === 'passwordRepeat'}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            if (validError.length) {
              setValidError('');
            }

            dispatch(setPasswordTemp(event.target.value.replace(/\s/g, '')));
          }}
        />
        <UIRegistrationInput
          id={'repeat_password'}
          label={'Repeat password'}
          type='password'
          value={isUserRepeatPass}
          error={validError === 'empty' || validError === 'passwordRepeat'}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            if (validError.length) {
              setValidError('');
            }

            setUserRepeatPass(event.target.value);
          }}
        />
        {/*<div className={'registration__notify'}>*/}
        {/*  <span className='registration__notify--title'>*/}
        {/*    How would you like to receive job notification?*/}
        {/*  </span>*/}
        {/*  <div className='registration__notify--list'>*/}
        {/*    <UIRadioButton*/}
        {/*      label={'Telegram bot'}*/}
        {/*      id={'telegram'}*/}
        {/*      checked={chosenNotifications.includes('Telegram bot')}*/}
        {/*      onChange={event =>*/}
        {/*        setChosenNotifications(prev =>*/}
        {/*          event.target.value === '1'*/}
        {/*            ? [...prev, 'Telegram bot']*/}
        {/*            : prev.filter(item => item !== 'Telegram bot')*/}
        {/*        )*/}
        {/*      }*/}
        {/*    />*/}
        {/*    <UIRadioButton*/}
        {/*      label={'Email'}*/}
        {/*      id={'email'}*/}
        {/*      checked={chosenNotifications.includes('Email')}*/}
        {/*      onChange={event =>*/}
        {/*        setChosenNotifications(prev =>*/}
        {/*          event.target.value === '1'*/}
        {/*            ? [...prev, 'Email']*/}
        {/*            : prev.filter(item => item !== 'Email')*/}
        {/*        )*/}
        {/*      }*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</div>*/}
        {validError && (
          <span className='registration__error'>
            {errorDictionary[validError as keyof typeof errorDictionary]}
          </span>
        )}
        {error && error === 'Please login or reset password' ? (
          <div className='registration__error'>
            Please{' '}
            <p
              className={'login_link'}
              onClick={() => dispatch(openModal('login'))}
            >
              login
            </p>{' '}
            or{' '}
            <p
              className={'login_link'}
              onClick={() => dispatch(openModal('enterPhone'))}
            >
              reset password
            </p>
          </div>
        ) : (
          error && <p className='registration__error'>{error}</p>
        )}
        <div className='registration__submit'>
          <UIButton
            title='Register'
            type='submit'
            classModificator='Button__modal--submit'
          />
        </div>
      </form>
    </div>
  );
};

export default RegistrationModalContent;
