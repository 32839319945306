import { AppDispatch } from '../store';
import { setLoading } from '../reducers/ui/HandlerSlice';
import { employerAPI } from '../../core/api/api';
import { InvoicesParams } from '../../core/models/models';
import { setEmployerInvoices } from '../reducers/api/UserInvoicesSlice';

export const getEmployerInvoices =
  (params: InvoicesParams) => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    return employerAPI
      .getEmployerInvoices(params)
      .then(response => {
        dispatch(setEmployerInvoices(response.data));
      })
      .catch(error => {
        // dispatch(openErrorModal(modalContentTexts.error.text));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
