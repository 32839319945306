import React, { FC, useCallback } from 'react';
import styles from './_styles.module.scss';
import { useAppSelector } from '../../../../../core/hooks/reduxHooks';
import { IPendingApprovalJobResult } from '../../../../../core/models/models';
import JobHeading from '../../../../Candidate/JobDetails/JobHeading';
import JobCardDetails from '../../../../Candidate/JobDetails/JobCardDetails/JobCardDetails';
import JobCardType from '../../../../Candidate/JobDetails/JobCardDetails/JobCardType';
import { CircularProgress } from '@mui/material';
import JobStatus from '../../../../UI/JobStatus/JobStatus';

const PostedJobList: FC<{
  selectedJobId: string | null;
  onClick: (id: string) => void;
}> = ({ selectedJobId, onClick }) => {
  const { pendingApproval } = useAppSelector(state => state.postUserJobReducer);

  const jobCard = useCallback(
    (job: IPendingApprovalJobResult) => {
      return (
        <div
          key={job.assignment_id}
          className={`${styles.job_card} ${
            selectedJobId === job.assignment_id ? styles.job_card_active : ''
          }`}
          onClick={() => onClick(job.assignment_id)}
        >
          <JobHeading heading={job.title} size={18} />
          <JobCardDetails
            companyName={job.company_name}
            location={job.location}
            time={job.time}
            lowestRate={job.lowest_rate}
            highestRate={job.highest_rate}
            expectedSalaryDivider={job.expected_salary_divider}
          />
          <div className={styles.footer}>
            <JobStatus className={styles.status} status={job.status} />
            <div className={styles.footer_info}>
              <JobCardType type={job.job_type} />
              <span>{job.date}</span>
            </div>
          </div>
        </div>
      );
    },
    [selectedJobId]
  );

  if (pendingApproval === null) {
    return <CircularProgress />;
  }

  return (
    <div className={styles.posted_list}>
      {pendingApproval.results.map(job => {
        return jobCard(job);
      })}
    </div>
  );
};

export default PostedJobList;
