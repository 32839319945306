import React, { FC, useCallback, useEffect, useRef } from 'react';
import styles from './_styles.module.scss';
import JobCardDetails from '../../Candidate/JobDetails/JobCardDetails/JobCardDetails';
import JobDetailsQualification from '../../Candidate/JobDetails/JobDetailsQualification';
import JobDetailsDescription from '../../Candidate/JobDetails/JobDetailsDescription';
import JobHeading from '../../Candidate/JobDetails/JobHeading';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';
import WorkDate from '../WorkCardMob/WorkDate';
import UIButton from '../../UI/UIButton/UIButton';
import { useAppDispatch, useAppSelector } from '../../../core/hooks/reduxHooks';
import {
  openApplyingModal,
  openModal,
} from '../../../redux/reducers/ui/ModalDialogSlice';
import {
  deleteSaveAssignment,
  saveAssignment,
} from '../../../redux/thunk/assignments';
import { setLoading } from '../../../redux/reducers/ui/HandlerSlice';
import { getAssignmentCandidateJobDetails } from '../../../core/services/axios';
import { setSelectedJob } from '../../../redux/reducers/api/CandidateAssignmentsSlice';
import { useParams, useSearchParams } from 'react-router-dom';
import JobCardType from '../../Candidate/JobDetails/JobCardDetails/JobCardType';
import LocationWithLinks from '../../LocationWithLinks/LocationWithLinks';
import JobDetailsWrap from '../../../containers/JobDetailsWrap/JobDetailsWrap';

const SearchWorkCardFullInfo: FC = () => {
  const { selectedJob } = useAppSelector(state => state.candidateAssignments);
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useAppDispatch();
  const { id } = useParams();

  const token = useAppSelector(state => state.userTokenReducer.token);

  dayjs.extend(relativeTime);

  const handleSaveAssignment = useCallback((jobId: string, save: boolean) => {
    if (save) {
      dispatch(saveAssignment(jobId));
    } else {
      dispatch(deleteSaveAssignment(jobId));
    }
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(setLoading(true));
      try {
        getAssignmentCandidateJobDetails(id).then(res => {
          if (res) {
            dispatch(setSelectedJob(res));
          }
        });
        dispatch(setLoading(false));
      } catch (error) {
        dispatch(setLoading(false));
      }
    }
  }, [id]);

  return (
    <div className={styles.work_full_info}>
      {selectedJob && (
        <>
          <div className={styles.work_info_wrap}>
            <div className={styles.work_info_main}>
              <div className={styles.work_info_main_text}>
                <JobHeading heading={selectedJob.title} size={20} />
                <JobDetailsWrap
                  companyName={selectedJob.company_name}
                  location={selectedJob.location}
                  lowestRate={selectedJob.lowest_rate}
                  highestRate={selectedJob.highest_rate}
                  expectedSalaryDivider={selectedJob.expected_salary_divider}
                  time={selectedJob.time}
                  locationLinks={selectedJob.location_links}
                  fullDetails={true}
                />
                <div className={styles.work_info_buttons}>
                  <UIButton
                    title={selectedJob.applied ? 'Applied' : 'Apply'}
                    disabled={selectedJob.applied}
                    callback={() => {
                      if (token) {
                        dispatch(openApplyingModal(selectedJob.assignment_id));
                      } else {
                        dispatch(openModal('login'));
                      }
                    }}
                  />
                  {token && (
                    <UIButton
                      title={selectedJob.saved ? 'Saved' : 'Save'}
                      classModificator={`${styles.save_btn}${
                        selectedJob.saved ? ` ${styles.save_btn_saved}` : ''
                      }`}
                      callback={() =>
                        handleSaveAssignment(
                          selectedJob.assignment_id,
                          !selectedJob.saved
                        )
                      }
                    />
                  )}
                </div>
              </div>
              <div className={styles.typeAndDate}>
                <JobCardType type={selectedJob.job_type} />
                {/*<WorkDate date={dayjs(work.date).fromNow()} />*/}
                <WorkDate date={selectedJob.created_at} />
              </div>
            </div>
          </div>

          <div
            className={`${styles.work_info_wrap} ${styles.work_info_wrap_padding}`}
          >
            <JobDetailsQualification
              industry={selectedJob.category}
              qualifications={selectedJob.preference}
              fullWidth={true}
            />
          </div>

          <JobDetailsDescription description={selectedJob.description} />
        </>
      )}
    </div>
  );
};

export default SearchWorkCardFullInfo;
