import { AppDispatch } from '../store';
import { apiClient } from '../../core/services/axios';
import {
  ICandidateJobsChannelsContent, IEmployerContent,
  IWebContent,
  setCandidateJobsChannelsContent, setEmployerCandidateCardContent,
  setLoadingWebContent,
  setWebContent,
} from '../reducers/ui/WebContentSlice';

export const getWebContent = () => (dispatch: AppDispatch) => {
  dispatch(setLoadingWebContent(true));

  return apiClient
    .get<IWebContent>('texts')
    .then(response => {
      dispatch(setWebContent(response.data));
    })
    .catch(err => {
      // dispatch(openErrorModal(modalContentTexts.error.text));
    })
    .finally(() => {
      dispatch(setLoadingWebContent(false));
    });
};

export const getCandidateJobsChannelsContent =
  () => (dispatch: AppDispatch) => {
    return apiClient
      .get<ICandidateJobsChannelsContent>('content/candidate_page')
      .then(response => {
        dispatch(setCandidateJobsChannelsContent(response.data));
      })
      .catch(err => {
        // dispatch(openErrorModal(modalContentTexts.error.text));
      })
      .finally(() => {
        dispatch(setLoadingWebContent(false));
      });
  };

export const getEmployerCandidateCardContent =
  () => (dispatch: AppDispatch) => {
    return apiClient
      .get<IEmployerContent>('content/employer_page')
      .then(response => {
        dispatch(setEmployerCandidateCardContent(response.data));
      })
      .catch(err => {
        // dispatch(openErrorModal(modalContentTexts.error.text));
      })
      .finally(() => {
        dispatch(setLoadingWebContent(false));
      });
  };
