import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useAppDispatch, useAppSelector } from '../../../core/hooks/reduxHooks';
import './LoginModal.scss';
import UIModalDialogHeader from '../../UI/UIModalDialog/UIModalDialogHeader';
import UIButton from '../../UI/UIButton/UIButton';
import UILoginPhone from '../../UI/UILogin/UILoginPhone';
import UILoginPass from '../../UI/UILogin/UILoginPass';
import UIForgotPass from '../../UI/UILogin/UIForgotPass';
import UIRegisterLink from '../../UI/UILogin/UIRegisterLink';
// import { setError } from '../reducers/api/UserTokenSlice';
import {
  setError,
  setPasswordTemp,
  setUserPhoneNumber,
} from '../../../redux/reducers/api/UserTokenSlice';
import { phoneRegex } from '../../../core/constants/regex';
import { userLogin } from '../../../redux/thunk/login';

const LoginModal: FC = () => {
  const [isPass, setPass] = useState('');
  const { hp_number, error } = useAppSelector(state => state.userTokenReducer);

  const dispatch = useAppDispatch();

  const modalDialogRef = useRef<HTMLFormElement>(null);

  const onSubmitLogin = useCallback(
    async (event: React.SyntheticEvent) => {
      event.preventDefault();

      if (!hp_number.length || !isPass.length) {
        setError('empty');
      } else {
        await dispatch(userLogin({ hp_number: hp_number, password: isPass }));
      }
    },
    [hp_number, isPass]
  );

  useEffect(() => {
    dispatch(setError(''));
    dispatch(setPasswordTemp(''));
    dispatch(setUserPhoneNumber(''));
  }, []);

  return (
    <form
      onSubmit={onSubmitLogin}
      ref={modalDialogRef}
      className='modal__dialog auth'
    >
      <UIModalDialogHeader heading={'Login'} />
      <div className='auth__login'>
        <UILoginPhone
          color={'secondary'}
          value={hp_number}
          onChange={e => {
            if (error.length) {
              dispatch(setError(''));
            }

            if (
              e.target.value.match(phoneRegex) &&
              e.target.value.length < 13
            ) {
              dispatch(setUserPhoneNumber(e.target.value));
            }
          }}
          error={!!error.length}
        />
        <UILoginPass
          idInput={'login'}
          color={'secondary'}
          value={isPass}
          onChange={e => {
            if (error.length) {
              dispatch(setError(''));
            }

            setPass(e.target.value);
          }}
          error={!!error.length}
        />
        <UIForgotPass />
      </div>
      <div className='auth__submit'>
        {error && (
          <span className='auth__error'>
            {error === 'empty'
              ? 'Please complete all fields'
              : 'Incorrect phone or password'}
          </span>
        )}
        <UIButton
          type='submit'
          title={'Login'}
          classModificator={'Button__modal--submit'}
        />
      </div>
      <div className='auth__register'>
        <span>{"Don't have an account?"}</span> <UIRegisterLink />
      </div>
    </form>
  );
};

export default LoginModal;
