import React, { ChangeEventHandler, FC } from 'react';
import styles from './_styles.module.scss';
import { PreferredJobTypes } from '../../core/models/models';
import useWebContent from '../../core/hooks/useWebContent';

const ProposedSalary: FC<{
  typeOfJob: PreferredJobTypes | '';
  onChangeLowestRate?: ChangeEventHandler<HTMLInputElement>;
  onChangeHighestRate: ChangeEventHandler<HTMLInputElement>;
  title?: string;
  lowestRate?: string;
  highestRate?: string;
  error?: boolean;
  color?: 'candidate' | 'employer' | 'admin';
  className?: string;
}> = ({
  typeOfJob,
  lowestRate,
  highestRate,
  error,
  color,
  title ,
  className,
  onChangeLowestRate,
  onChangeHighestRate,
  ...rest
}) => {
  const [webContent] = useWebContent();

  return (
    <label className={`${styles.salary} ${className || ''}`}>
      <span className={`${styles.title} ${error && styles.title_error}`}>
        {title || webContent.job_proposed_salary_web}
      </span>
      <span className={`${error && styles.title_error}`}>&nbsp;$</span>
      {onChangeLowestRate && (
        <>
          <input
            id={'lowestRate'}
            type='text'
            className={`${styles.input} ${error && styles.error} ${
              color === 'candidate'
                ? styles.input_candidate
                : color === 'employer'
                ? styles.input_emplr
                : styles.input_admin
            }`}
            value={lowestRate}
            onChange={event => onChangeLowestRate(event)}
          />
          <span className={`${styles.to} ${error && styles.title_error}`}>
            to
          </span>
        </>
      )}
      <input
        id={'highestRate'}
        type='text'
        className={`${styles.input} ${error && styles.error} ${
          color === 'candidate'
            ? styles.input_candidate
            : color === 'employer'
            ? styles.input_emplr
            : styles.input_admin
        }`}
        value={highestRate}
        onChange={event => onChangeHighestRate(event)}
      />
      <p className={`${error && styles.title_error}`}>
        {' '}
        {typeOfJob === 'Part-time' ? '/hr' : '/month'}
      </p>
    </label>
  );
};

export default ProposedSalary;
