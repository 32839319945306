import React, { FC, useEffect } from 'react';
import ProfileInformation from '../../components/Candidate/Profile/ProfileInformation';
import { useAppDispatch, useAppSelector } from '../../core/hooks/reduxHooks';
import { clearAfterRegister } from '../../redux/reducers/api/UserTokenSlice';
import RequireAuth from '../../containers/hoc/RequireAuth';
import RequireCompleteProfile from '../../containers/CandidateLayout/RequireCompleteProfile';
import {
  getCandidateProfileInfo,
  getProfileStatus,
} from '../../redux/thunk/profile';

const CandidateProfile: FC = () => {
  const dispatch = useAppDispatch();

  const { afterRegister, token } = useAppSelector(
    state => state.userTokenReducer
  );
  const { information } = useAppSelector(state => state.userInformationReducer);

  useEffect(() => {
    dispatch(getCandidateProfileInfo());
    dispatch(getProfileStatus());

    return () => {
      dispatch(getProfileStatus());
      if (afterRegister) {
        dispatch(clearAfterRegister());
      }
    };
  }, [token]);

  return (
    <RequireAuth role={'candidate'}>
      <RequireCompleteProfile>
        <div className='profile profile-candidate'>
          {Object.keys(information).length === 0 ? (
            <></>
          ) : (
            <ProfileInformation registration={afterRegister} />
          )}
        </div>
      </RequireCompleteProfile>
    </RequireAuth>
  );
};

export default CandidateProfile;
