import { AppDispatch } from '../store';
import { setLoading } from '../reducers/ui/HandlerSlice';
import { employerAPI } from '../../core/api/api';
import {
  setSubscriptionBumpPackages,
  setSubscriptionPackages,
  setSubscriptionShortlistPackages,
} from '../reducers/ui/SubscriptionPackagesSlice';

export const getSubscriptionPackages = () => (dispatch: AppDispatch) => {
  dispatch(setLoading(true));

  return employerAPI
    .getSubscriptionPackages()
    .then(response => {
      dispatch(setSubscriptionPackages(response.data));
    })
    .catch(err => {
      // dispatch(openErrorModal(modalContentTexts.error.text));
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
};

export const getSubscriptionBumpPackages = () => (dispatch: AppDispatch) => {
  dispatch(setLoading(true));

  return employerAPI
    .getSubscriptionBumpPackages()
    .then(response => {
      dispatch(setSubscriptionBumpPackages(response.data));
    })
    .catch(err => {
      // dispatch(openErrorModal(modalContentTexts.error.text));
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
};

export const getSubscriptionShortlistPackages =
  () => (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    return employerAPI
      .getSubscriptionShortlistPackages()
      .then(response => {
        dispatch(setSubscriptionShortlistPackages(response.data));
      })
      .catch(err => {
        // dispatch(openErrorModal(modalContentTexts.error.text));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
