import React from 'react';
import './Footer.scss';
import { tgChannels } from '../../core/constants/links';
import useWebContent from '../../core/hooks/useWebContent';

const Footer = () => {
  const [webContent] = useWebContent();

  return (
    <div className='footer-wrapper'>
      <div className='footer'>
        <div className='footer__contact--mobile contact'>
          <p className='contact__heading'>Contact Us</p>
          <div className='contact__email'>
            <div className='contact__email--icon footer__icon' />
            <a
              href={`mailto:${webContent.email_web}`}
              className='contact__email--address'
              target='_blank'
              rel='author noreferrer'
            >
              {webContent.email_web}
            </a>
          </div>
          <div className='contact__numbers'>
            <a
              href={`tel://${webContent.phone_web}`}
              className='contact__phone'
            >
              <div className='contact__phone--icon footer__icon' />
              {webContent.phone_web}
            </a>
            <span>or</span>
            <a
              href={`${webContent.whatsapp_link_web}`}
              target='_blank'
              rel='noreferrer'
              className='contact__whats-app'
            >
              <div className='contact__whats-app--icon footer__icon' /> WhatsApp
              Us
            </a>
          </div>
          <ul className='contact__tg-list'>
            <li>
              <a
                href={webContent.contact_us_channel_one_link_web}
                target='_blank'
                rel='noreferrer'
                className='contact__tg-list--link'
              >
                <p>{webContent.contact_us_channel_one_handle_web}</p>
                <div className='contact__tg-list--icon' />
              </a>
            </li>
            <li>
              <a
                href={webContent.contact_us_channel_two_link_web}
                target='_blank'
                rel='noreferrer'
                className='contact__tg-list--link'
              >
                <p>{webContent.contact_us_channel_two_handle_web}</p>
                <div className='contact__tg-list--icon' />
              </a>
            </li>
            <li>
              <a
                href={webContent.contact_us_channel_three_link_web}
                target='_blank'
                rel='noreferrer'
                className='contact__tg-list--link'
              >
                <p>{webContent.contact_us_channel_three_handle_web}</p>
                <div className='contact__tg-list--icon' />
              </a>
            </li>
          </ul>
        </div>

        <div className='footer__contact--desc contact'>
          <div className='contact__numbers'>
            <a
              href={`tel://${webContent.phone_web}`}
              className='contact__phone'
            >
              <div className='contact__phone--icon footer__icon' />
              {webContent.phone_web}
            </a>
            <span>or</span>
            <a
              href={`${webContent.whatsapp_link_web}`}
              target='_blank'
              rel='noreferrer'
              className='contact__whats-app'
            >
              <div className='contact__whats-app--icon footer__icon' /> WhatsApp
              Us
            </a>
          </div>

          <ul className='contact__tg-list'>
            <li>
              <a
                href={webContent.contact_us_channel_one_link_web}
                target='_blank'
                rel='noreferrer'
                className='contact__tg-list--link'
              >
                <p>{webContent.contact_us_channel_one_handle_web}</p>
                <div className='contact__tg-list--icon' />
              </a>
            </li>
            <li>
              <a
                href={webContent.contact_us_channel_two_link_web}
                target='_blank'
                rel='noreferrer'
                className='contact__tg-list--link'
              >
                <p>{webContent.contact_us_channel_two_handle_web}</p>
                <div className='contact__tg-list--icon' />
              </a>
            </li>
            <li>
              <a
                href={webContent.contact_us_channel_three_link_web}
                target='_blank'
                rel='noreferrer'
                className='contact__tg-list--link'
              >
                <p>{webContent.contact_us_channel_three_handle_web}</p>
                <div className='contact__tg-list--icon' />
              </a>
            </li>
          </ul>

          <div className='contact__email'>
            <div className='contact__email--icon footer__icon' />
            <a
              href={`mailto:${webContent.email_web}`}
              className='contact__email--address'
              target='_blank'
              rel='author noreferrer'
            >
              {webContent.email_web}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
