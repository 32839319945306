import React, { FormEventHandler, useCallback, useState } from 'react';
import UIInput from '../../UI/UIInput/UIInput';
import UIButton from '../../UI/UIButton/UIButton';
import UIModalDialogHeader from '../../UI/UIModalDialog/UIModalDialogHeader';
import styles from './_styles.module.scss';
import { useAppDispatch, useAppSelector } from '../../../core/hooks/reduxHooks';
import { setUserPhoneNumber } from '../../../redux/reducers/api/UserTokenSlice';
import { updatePhoneNumber } from '../../../redux/thunk/profile';
import { useLocation } from 'react-router-dom';
import { openModal } from '../../../redux/reducers/ui/ModalDialogSlice';
import { phoneRegex } from '../../../core/constants/regex';

const NewPhone: React.FC<{ type: 'new' | 'enter' }> = ({ type }) => {
  const dispatch = useAppDispatch();
  const hp_number = useAppSelector(state => state.userTokenReducer.hp_number);
  const [validError, setValidError] = useState('');
  const location = useLocation();

  const updatePhone: FormEventHandler = event => {
    event.preventDefault();

    if (!hp_number.length) {
      setValidError('empty');
    } else {
      if (type === 'new') {
        dispatch(updatePhoneNumber({ hp_number }));
      } else {
        dispatch(openModal('updatePasswordLogin'));
      }
    }
  };

  return (
    <form onSubmit={updatePhone}>
      <UIModalDialogHeader
        heading={type === 'new' ? 'new phone number' : 'phone number'}
      />
      <div className={styles.phone_wrapper}>
        <UIInput
          id='new-phone'
          type='tel'
          phone
          icon='phone'
          value={hp_number}
          error={validError === 'empty'}
          onChange={e => {
            if (validError.length) {
              setValidError('');
            }

            if (
              e.target.value.match(phoneRegex) &&
              e.target.value.length < 13
            ) {
              dispatch(setUserPhoneNumber(e.target.value));
            }
          }}
          borderColor={'secondary'}
        />
        <UIButton title='Submit' type='submit' />
      </div>
    </form>
  );
};

export default NewPhone;
