import React, { FC } from 'react';
import './WorkCardMob.scss';
import UIButton from '../../UI/UIButton/UIButton';
import JobCardDetails from '../../Candidate/JobDetails/JobCardDetails/JobCardDetails';
import JobHeading from '../../Candidate/JobDetails/JobHeading';
import { IAvailableWorkDetails } from '../../../core/models/models';
import { useAppDispatch, useAppSelector } from '../../../core/hooks/reduxHooks';
import {
  openApplyingModal,
  openModal,
} from '../../../redux/reducers/ui/ModalDialogSlice';
import { useNavigate, useSearchParams } from 'react-router-dom';
import JobCardType from '../../Candidate/JobDetails/JobCardDetails/JobCardType';

export type WorkCardProps = {
  work: IAvailableWorkDetails;
};

const WorkCardMob: FC<WorkCardProps> = ({ work }) => {
  const token = useAppSelector(state => state.userTokenReducer.token);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const openJobDetails = () => {
    // dispatch(setSelectedJob(work));
    navigate(`/${work.assignment_id}/job-details`);
  };

  return (
    <div className='work__card card'>
      <div className='card__header'>
        <JobCardType type={work.job_type} />
      </div>
      <div className={'card__info'}>
        <JobHeading heading={work.title} size={18} />
        <JobCardDetails
          companyName={work.company_name}
          location={work.location}
          lowestRate={work.lowest_rate}
          highestRate={work.highest_rate}
          time={work.time}
          expectedSalaryDivider={work.expected_salary_divider}
        />
      </div>
      <div className='card__buttons'>
        <UIButton
          title={'Details'}
          classModificator={'Button--details'}
          callback={() => {
            openJobDetails();
          }}
        />
        <UIButton
          title={work.applied ? 'Applied' : 'Apply'}
          disabled={work.applied}
          classModificator={'--apply'}
          callback={() => {
            if (token) {
              dispatch(openApplyingModal(work.assignment_id));
            } else {
              dispatch(openModal('login'));
            }
          }}
        />
      </div>
    </div>
  );
};

export default WorkCardMob;
