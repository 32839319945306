import React, { useEffect } from 'react';
import { useAppSelector } from './reduxHooks';

const useWebContent = () => {
  const { webContent } = useAppSelector(state => state.WebContentReducer);

  return [webContent];
};

export default useWebContent;
