import React, { FC, useEffect, useMemo, useState } from 'react';
import styles from './_styles.module.scss';
import { IReviewCandidatesResult } from '../../../../core/models/models';
import UIFilterSelectBox from '../../../UI/UIFilterSelectBox/UIFilterSelectBox';
import ReviewCandidateDetails from '../../../ReviewCandidateDetails/ReviewCandidateDetails';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../core/hooks/reduxHooks';
import { getAssignmentJobReviewCandidates } from '../../../../redux/thunk/employerAssignments';
import { getAdminAssignmentJobReviewCandidateInfo } from '../../../../redux/thunk/adminAssignments';
import UIPagination from '../../../UI/UIPagination/UIPagination';
import { CircularProgress } from '@mui/material';
import { setEmptyJobReviewCandidates } from '../../../../redux/reducers/api/UserManageJobsSlice';
import { setEmptyAdminJobReviewCandidates } from '../../../../redux/reducers/api/AdminAssignmentSlice';

const orderByOptions = ['Shortlisted', 'Not shortlisted', 'Latest', 'Oldest'];

type OrderByType = 'Latest' | 'Oldest' | 'Shortlisted' | 'Not shortlisted';

const ReviewCandidatesContent: FC<{
  jobId: string | undefined;
  role: 'employer' | 'admin';
}> = ({ jobId, role }) => {
  const [isOrderBy, setOrderBy] = useState<OrderByType>('Latest');
  const [isCurrentPage, setCurrentPage] = useState<number>(1);
  const dispatch = useAppDispatch();

  const { jobReviewCandidates, isLoadingCandidates, jobDetails } =
    useAppSelector(state => state.UserManageJobsReducer);

  const { jobReviewCandidatesAdmin, jobReviewCandidatesAdminLoading } =
    useAppSelector(state => state.adminAssignments);

  const { all, posted_by_staff } = useAppSelector(
    state => state.adminAssignments
  );

  const isReviewCandidates = useMemo(() => {
    if (role === 'employer') {
      return jobReviewCandidates;
    } else if (role === 'admin') {
      return jobReviewCandidatesAdmin;
    }
  }, [
    role,
    jobId,
    jobReviewCandidates.results.length,
    jobReviewCandidatesAdmin.results.length,
    jobDetails?.assignment_id,
    jobDetails?.shortlists_left,
  ]);

  useEffect(() => {
    setCurrentPage(1);
  }, [jobId]);

  useEffect(() => {
    if (jobId && role === 'employer') {
      dispatch(
        getAssignmentJobReviewCandidates(jobId, isOrderBy, isCurrentPage)
      );
    }

    if (jobId && role === 'admin') {
      dispatch(
        getAdminAssignmentJobReviewCandidateInfo(
          jobId,
          isOrderBy,
          isCurrentPage
        )
      );
    }
  }, [jobId, role, isOrderBy, isCurrentPage]);

  return (
    <div className={styles.content}>
      <h3 className={styles.heading}>Review candidates</h3>
      {isLoadingCandidates || jobReviewCandidatesAdminLoading ? (
        <CircularProgress className={styles.circular} />
      ) : (
        <div className={styles.reviews}>
          <UIFilterSelectBox
            list={orderByOptions}
            selectedOption={isOrderBy}
            setSelectedOption={setOrderBy}
          />
          {isReviewCandidates && isReviewCandidates.results.length > 0 ? (
            <>
              <ul className={styles.reviews_list}>
                {jobId &&
                  isReviewCandidates.results.map((item, index) => (
                    <ReviewCandidateDetails
                      assignmentId={jobId}
                      key={item.id}
                      details={item}
                      index={item.index}
                      role={role}
                    />
                  ))}
              </ul>
              {isReviewCandidates.totalPages > 1 && (
                <UIPagination
                  currentPage={isCurrentPage}
                  totalPages={isReviewCandidates.totalPages}
                  onChangePage={(event, page) => setCurrentPage(page)}
                />
              )}
            </>
          ) : (
            <div className={styles.reviews} style={{ textAlign: 'center' }}>
              No one has applied for the job yet.
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ReviewCandidatesContent;
