import React, { FC, useCallback, useEffect, useMemo } from 'react';
import styles from './_styles.module.scss';
import { IReviewCandidate } from '../../core/models/models';
import noPhoto from '../../assets/images/no_photo_candidate.png';
import UIReviewCandidateQuality from '../UI/UIReviewCandidateQuality/UIReviewCandidateQuality';
import ContactInfo from './ContactInfo';
import { useAppSelector } from '../../core/hooks/reduxHooks';
import useWebContent from "../../core/hooks/useWebContent";

const ReviewCandidateDetails: FC<{
  assignmentId: string;
  details: IReviewCandidate;
  role: 'employer' | 'admin';
  index: number;
}> = ({ assignmentId, details, index, role }) => {
  const { jobDetails } = useAppSelector(state => state.UserManageJobsReducer);
  const [webContent] = useWebContent();

  const profilePictureSrc = useMemo(
    () =>
      details.profile_picture_fs
        ? `data:image/jpeg;base64,${details.profile_picture_fs}`
        : noPhoto,
    [details]
  );

  return (
    <li
      className={`${styles.review}  ${
        details.shortlisted
          ? styles.review_shortlisted
          : styles.review_not_shortlisted
      }`}
    >
      <div className={styles.review_header}>
        <h4 className={styles.review_index}>Candidate {index}</h4>
        <div className={styles.photo_wrap}>
          <img
            src={profilePictureSrc}
            alt='Candidate photo'
            className={styles.photo}
          />
        </div>
      </div>

      <div className={styles.review_qualities}>
        {details.age !== null && (
          <UIReviewCandidateQuality title={webContent.age_web} quality={details.age} />
        )}
        {details.gender !== null && (
          <UIReviewCandidateQuality title={webContent.gender_web} quality={details.gender} />
        )}
        {details.race !== null && (
          <UIReviewCandidateQuality title={webContent.race_web} quality={details.race} />
        )}
        {details.years_teaching !== null && (
          <UIReviewCandidateQuality
            title={'Years of experience'}
            quality={details.years_teaching}
          />
        )}
        {details.citizenship !== null && (
          <UIReviewCandidateQuality
            title={webContent.nationality_web}
            quality={details.citizenship}
          />
        )}
        {details.group !== null && (
          <UIReviewCandidateQuality title={webContent.group_web} quality={details.group} />
        )}
        {details.qualification !== null && (
          <UIReviewCandidateQuality
            title={webContent.highest_qualification_web}
            quality={details.qualification}
          />
        )}
        <UIReviewCandidateQuality
          title={webContent.available_day_and_time_web}
          quality={details.day_and_time}
        />
        <UIReviewCandidateQuality
          title={webContent.expected_salary_web}
          quality={details.expected_salary}
        />
      </div>
      <div className={styles.contact_wrap}>
        <div className={styles.wrap}>
          <p className={styles.applied}>Applied on {details.applied_on}</p>
          {/*<p className={styles.shortlisted}>*/}
          {/*  {details.shortlisted ? 'Shortlisted' : 'Not shortlisted'}*/}
          {/*</p>*/}
        </div>
        <ContactInfo
          assignmentId={assignmentId}
          proposal_id={details.id}
          shortlistValue={
            role === 'employer' && jobDetails ? jobDetails.shortlists_left : 1
          }
          role={role}
        />
      </div>
      <UIReviewCandidateQuality
        title={webContent.details_of_experience_web}
        experience={details.experience}
      />
    </li>
  );
};

export default ReviewCandidateDetails;
