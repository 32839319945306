import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import styles from './_styles.module.scss';
import { useAppDispatch, useAppSelector } from '../../../core/hooks/reduxHooks';
import { modalDialogSlice } from '../../../redux/reducers/ui/ModalDialogSlice';
import JobHeading from '../../Candidate/JobDetails/JobHeading';
import JobCardDetails from '../../Candidate/JobDetails/JobCardDetails/JobCardDetails';
import UIInput from '../../UI/UIInput/UIInput';
import ProposedSalary from '../../ProposedSalary/ProposedSalary';
import UIFilterSelectBox from '../../UI/UIFilterSelectBox/UIFilterSelectBox';
import { proposedSalaryRegex } from '../../../core/constants/regex';
import { validateCurrentInputValue } from '../../../core/utils/validateCurrentInputValue';
import UITextarea from '../../UI/UITextarea/UITextarea';
import { setChosenApplyValue } from '../../../redux/reducers/api/CandidateAssignmentsSlice';
import {
  CandidateJobGroupType,
  IApplyJobPost,
} from '../../../core/models/models';
import UIButton from '../../UI/UIButton/UIButton';
import Icon from '../../UI/UIIcon/UIIcon';
import {
  applyAssignment,
  getApplyPosterInfo,
} from '../../../redux/thunk/assignments';
import JobCardType from '../../Candidate/JobDetails/JobCardDetails/JobCardType';
import RequireCompleteProfile from '../../../containers/CandidateLayout/RequireCompleteProfile';
import { getCandidateProfileInfo } from '../../../redux/thunk/profile';
import JobDetailsWrap from '../../../containers/JobDetailsWrap/JobDetailsWrap';
import useWebContent from '../../../core/hooks/useWebContent';

const ApplyModal: React.FC = () => {
  const [isChosenGroup, setChosenGroup] =
    useState<CandidateJobGroupType>('Undergraduate');
  const [isErrorInput, setErrorInput] = useState<boolean>(false);
  const [resume, setResume] = useState<File | null>(null);

  const applyFormRef = useRef(null);

  const [webContent] = useWebContent();
  const dispatch = useAppDispatch();

  const information = useAppSelector(
    state => state.userInformationReducer.information
  );
  const { applyJobPoster, applyJob } = useAppSelector(
    state => state.candidateAssignments
  );
  const { applyingAssignmentId } = useAppSelector(
    state => state.modalDialogReducer
  );

  const { closeModal } = modalDialogSlice.actions;

  useEffect(() => {
    if (information.highestQualification) {
      dispatch(
        setChosenApplyValue({
          key: 'highest_qualification',
          value: information.highestQualification,
        })
      );
    }

    if (information.chosenGroup) {
      dispatch(
        setChosenApplyValue({
          key: 'group',
          value: information.chosenGroup,
        })
      );
    }

    if (information.detailsOfExperience) {
      dispatch(
        setChosenApplyValue({
          key: 'details_of_experience',
          value: information.detailsOfExperience,
        })
      );
    }
  }, [
    information.highestQualification,
    information.chosenGroup,
    information.detailsOfExperience,
  ]);

  const onSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    (event: React.SyntheticEvent) => {
      event.preventDefault();

      if (!applyJobPoster) {
        return;
      }

      if (!applyJob.day_and_time || !applyJob.rate) {
        setErrorInput(true);

        return;
      }

      const userInfo: IApplyJobPost = {
        assignment_id: applyingAssignmentId,
        day_and_time: applyJob.day_and_time,
        rate: applyJob.rate,
        group: applyJob.group,
        highest_qualification: applyJob.highest_qualification,
        details_of_experience: applyJob.details_of_experience,
      };

      const userInfoData = new FormData();

      for (const key in userInfo) {
        userInfoData.append(
          key,
          userInfo[key as keyof typeof userInfo] as unknown as string
        );
      }

      if (resume) {
        userInfoData.append('resume', resume);
      }

      dispatch(applyAssignment(userInfoData));
    },
    [applyJob, applyJobPoster]
  );

  const onChangeRate = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (value.match(proposedSalaryRegex) || value === '') {
      dispatch(
        setChosenApplyValue({
          key: 'rate',
          value: value,
        })
      );
    }

    setErrorInput(false);
  };

  const onCloseModal = () => {
    dispatch(closeModal());
  };

  useLayoutEffect(() => {
    if (applyingAssignmentId) {
      dispatch(getCandidateProfileInfo());
      dispatch(getApplyPosterInfo(applyingAssignmentId as string));
    }
  }, [applyingAssignmentId]);

  if (!applyJobPoster) {
    return null;
  }

  const uploadResume = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    if (e.target.files) {
      setResume(e.target.files[0]);
    }
  };

  return (
    <RequireCompleteProfile>
      <div className={styles.modal}>
        <div className={styles.modal_head}>
          <h3 className={styles.heading}>Apply job</h3>
          <button
            className={styles.close}
            type='button'
            onClick={() => onCloseModal()}
          >
            <Icon name={'close'} className={styles.close_icon} />
          </button>
        </div>
        <div className={styles.job_info}>
          <div className={styles.job_info_head}>
            <JobHeading heading={applyJobPoster.level_subject} size={20} />
          </div>
          <JobDetailsWrap
            companyName={applyJobPoster.company_name}
            location={applyJobPoster.location}
            lowestRate={applyJobPoster.lowest_rate}
            highestRate={applyJobPoster.highest_rate}
            expectedSalaryDivider={applyJobPoster.expected_salary_divider}
            time={applyJobPoster.day_and_time}
          />
          <JobCardType type={applyJobPoster.tutor_job_types} />
        </div>
        <form ref={applyFormRef} onSubmit={onSubmit}>
          <div className={styles.basic_form}>
            <UIInput
              id='dayAndTime'
              title='Propose your available day and time:'
              direction='row'
              fontSizeInput={14}
              className={styles.row_input}
              value={applyJob.day_and_time}
              error={isErrorInput && !applyJob.day_and_time}
              onChange={event => {
                setErrorInput(false);
                dispatch(
                  setChosenApplyValue({
                    key: 'day_and_time',
                    value: event.target.value,
                  })
                );
              }}
            />
            <ProposedSalary
              typeOfJob={applyJobPoster.tutor_job_types}
              title='Expected salary:'
              color={'employer'}
              error={isErrorInput && !applyJob.rate}
              highestRate={applyJob.rate}
              onChangeHighestRate={onChangeRate}
              className={styles.salary}
            />
            <UIInput
              id='qualification'
              title='Highest Qualification:'
              direction='row'
              fontSizeInput={14}
              className={styles.row_input}
              value={applyJob.highest_qualification}
              maxLength={applyJobPoster.highest_qualification_limit}
              onChange={event =>
                dispatch(
                  setChosenApplyValue({
                    key: 'highest_qualification',
                    value: event.target.value,
                  })
                )
              }
            />
            <div className={styles.select}>
              <p>Group:</p>
              <UIFilterSelectBox
                list={applyJobPoster.all_groups}
                selectedOption={applyJob?.group || isChosenGroup}
                setSelectedOption={option => {
                  setChosenGroup(option);
                  dispatch(
                    setChosenApplyValue({
                      key: 'group',
                      value: option,
                    })
                  );
                }}
                className={styles.select_box}
              />
            </div>
            <label className={styles.description}>
              <UITextarea
                id={'details_experience'}
                title={'Provide details of your past working experience:'}
                rows={10}
                fontSizeInput={14}
                maxLength={applyJobPoster.experience_limit}
                value={
                  applyJob.details_of_experience
                    ? validateCurrentInputValue(applyJob.details_of_experience)
                    : ''
                }
                placeholder='Please key in the your past working experience...'
                onChange={event => {
                  setErrorInput(false);
                  dispatch(
                    setChosenApplyValue({
                      key: 'details_of_experience',
                      value: validateCurrentInputValue(event.target.value),
                    })
                  );
                }}
                color={'employer'}
              />
            </label>
            <div className={styles.resume}>
              <button className={styles.resume_btn}>
                <input
                  type='file'
                  onChange={uploadResume}
                  accept='.pdf, .doc, .docx'
                />
                Upload resume
              </button>
              {(resume || applyJobPoster.resume_filename) && (
                <div className={styles.resume_file}>
                  {resume ? (
                    <span>{resume.name}</span>
                  ) : (
                    <span>{applyJobPoster.resume_filename}</span>
                  )}
                  {/*<Icon name={'close'} onClick={() => setResume(null)} />*/}
                </div>
              )}
            </div>

            <div className={styles.buttons}>
              {/*<UIButton*/}
              {/*  title={'Back'}*/}
              {/*  callback={() => onCloseModal()}*/}
              {/*  classModificator={styles.back_btn}*/}
              {/*/>*/}
              <UIButton
                title={'Submit'}
                type={'submit'}
                classModificator={styles.submit_btn}
              />
            </div>
          </div>

          <article className={styles.article}>
            {webContent.consent_text_web}
          </article>
        </form>
      </div>
    </RequireCompleteProfile>
  );
};

export default ApplyModal;
