import { AppDispatch, AppStore, RootState } from '../store';
import { setLoading } from '../reducers/ui/HandlerSlice';
import { API } from '../../core/api/api';
import {
  closeModal,
  openErrorModal,
  openInfoModal,
  openModal,
  setOtpTimestamp,
} from '../reducers/ui/ModalDialogSlice';
import {
  setError,
  setToken,
  setUserPhoneNumber,
  setVerifyEmailMessage,
} from '../reducers/api/UserTokenSlice';
import { downloadPDFBlob } from '../../core/utils/downloadBlob';
import { modalContentTexts } from '../../core/constants/modalsContentText';
import {
  setCandidateInformation,
  setEmployerInformation,
  setLoadingUpdateProfile,
  setProfileStatus,
  setVerifyEmail,
} from '../reducers/api/UserInformationSlice';
import { AxiosError } from 'axios';
import useWebContent from '../../core/hooks/useWebContent';

export const getProfileStatus =
  () => (dispatch: AppDispatch, getState: () => RootState) => {
    if (getState().userTokenReducer.isAdmin) {
      return {};
    }

    dispatch(setLoading(true));

    return API.getProfileStatus()
      .then(response => {
        dispatch(
          setProfileStatus({
            complete: response.data.complete,
            requiredFields: response.data.required_fields,
          })
        );
      })
      .catch(err => {
        // if (err.response) {
        //   if (typeof err.response.data.message === 'string') {
        //     dispatch(openErrorModal(modalContentTexts.error.text));
        //   } else {
        //     dispatch(openErrorModal(modalContentTexts.error.text));
        //   }
        // } else if (err.request) {
        //   dispatch(openErrorModal(modalContentTexts.error.text));
        // } else {
        //   dispatch(openErrorModal(modalContentTexts.error.text));
        // }
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

export const verifyUserEmail = (token: string) => (dispatch: AppDispatch) => {
  dispatch(setLoading(true));

  return API.verifyUserEmail(token)
    .then(response => {
      // console.log(response);
      dispatch(setVerifyEmailMessage(response.data.message));
    })
    .catch(err => {
      dispatch(setVerifyEmailMessage(err.data.message));
      // console.log(err);
      // if (err.response) {
      //   if (typeof err.response.data.message === 'string') {
      //     dispatch(openErrorModal(modalContentTexts.error.text));
      //   } else {
      //     dispatch(openErrorModal(modalContentTexts.error.text));
      //   }
      // } else if (err.request) {
      //   dispatch(openErrorModal(modalContentTexts.error.text));
      // } else {
      //   dispatch(openErrorModal(modalContentTexts.error.text));
      // }
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
};

export const verifyUserEmailUnsubscribeJobs =
  (token: string) => (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    return API.verifyUserEmailUnsubscribeJobs(token)
      .then(response => {
        // console.log(response);
        dispatch(setVerifyEmailMessage(response.data.message));
      })
      .catch(err => {
        dispatch(setVerifyEmailMessage(err.data.message));
        // console.log(err);
        // if (err.response) {
        //   if (typeof err.response.data.message === 'string') {
        //     dispatch(openErrorModal(modalContentTexts.error.text));
        //   } else {
        //     dispatch(openErrorModal(modalContentTexts.error.text));
        //   }
        // } else if (err.request) {
        //   dispatch(openErrorModal(modalContentTexts.error.text));
        // } else {
        //   dispatch(openErrorModal(modalContentTexts.error.text));
        // }
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

export const verifyUserEmailUnsubscribeCandidates =
  (token: string) => (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    return API.verifyUserEmailUnsubscribeCandidates(token)
      .then(response => {
        // console.log(response);
        dispatch(setVerifyEmailMessage(response.data.message));
      })
      .catch(err => {
        dispatch(setVerifyEmailMessage(err.data.message));
        // console.log(err);
        // if (err.response) {
        //   if (typeof err.response.data.message === 'string') {
        //     dispatch(openErrorModal(modalContentTexts.error.text));
        //   } else {
        //     dispatch(openErrorModal(modalContentTexts.error.text));
        //   }
        // } else if (err.request) {
        //   dispatch(openErrorModal(modalContentTexts.error.text));
        // } else {
        //   dispatch(openErrorModal(modalContentTexts.error.text));
        // }
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

export const getCandidateProfileInfo = () => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));

  return API.getCandidateProfileInfo()
    .then(res => {
      dispatch(setCandidateInformation(res.data));
    })
    .catch(error => {
      // console.log(error);
      // dispatch(openErrorModal(modalContentTexts.error.text));
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
};

export const getEmployerProfileInfo = () => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));

  return API.getEmployerProfileInfo()
    .then(res => {
      dispatch(setEmployerInformation(res.data));
    })
    .catch(error => {
      // console.log(error);
      dispatch(openErrorModal(modalContentTexts.error.text));
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
};

export const updateEmployerProfileInfo =
  (data: FormData) => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    return API.updateEmployerProfileInfo(data)
      .then(res => {
        if (res.data) {
          dispatch(
            openInfoModal({
              message: 'Profile successfully updated',
              title: 'Success',
            })
          );
        }
      })
      .catch(error => {
        // console.log(error);
        dispatch(openErrorModal(modalContentTexts.error.text));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

export const updateCandidateProfileInfo =
  (data: FormData) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    // dispatch(setLoading(true));
    dispatch(setLoadingUpdateProfile(true));

    const webContent = getState().WebContentReducer.webContent;

    return API.updateCandidateProfileInfo(data)
      .then(res => {
        dispatch(getProfileStatus());

        if (res.data.status === 'success') {
          if (getState().userInformationReducer.complete) {
            dispatch(
              openInfoModal({
                message: webContent.profile_updated_web,
                title: 'Success',
              })
            );
          } else {
            openInfoModal({
              title: '',
              message: webContent.profile_incomplete_web,
            });
          }
        }
      })
      .catch(error => {
        dispatch(openErrorModal(error.response.data.message.join(`\r\n`)));
      })
      .finally(() => {
        dispatch(setLoadingUpdateProfile(false));
      });
  };

export const verifyEmailHandler =
  (payload: { email: string }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setLoading(true));

    const webContent = getState().WebContentReducer.webContent;

    return API.verifyEmailHandler(payload)
      .then(res => {
        // console.log('res', res);
        if (res.data) {
          dispatch(
            openInfoModal({
              message: webContent.email_verification_sent_web,
              title: 'Verify email',
            })
          );
        }
      })
      .catch(error => {
        // console.log(error);
        dispatch(openErrorModal(modalContentTexts.error.text));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

export const updatePhoneNumber =
  (payload: { hp_number: string }) => (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    return API.updatePhoneNumber(payload)
      .then(response => {
        dispatch(setOtpTimestamp(response.data.otp_expiration_timestamp));
        dispatch(openModal('verifyPhoneUpdate'));
      })
      .catch(err => {
        if (err.response) {
          if (typeof err.response.data.message === 'string') {
            dispatch(openErrorModal(err.response.data.message));
          } else {
            dispatch(openErrorModal(err.response.status));
          }
        } else if (err.request) {
          dispatch(openErrorModal(err.request.data || err.message));
        } else {
          dispatch(openErrorModal(err.message));
        }
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

export const verifyUpdatePhoneNumber =
  (payload: { code: string; hp_number: string }) => (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    return API.verifyUpdatePhoneNumber(payload)
      .then(() => {
        dispatch(getCandidateProfileInfo());
        dispatch(closeModal());
      })
      .catch(err => {
        if (err.response?.data) {
          dispatch(setError(err.response.data.message as string));
        } else {
          dispatch(setError(err.message));
        }
        // if (err.response) {
        //   if (typeof err.response.data.message === 'string') {
        //     dispatch(openErrorModal(err.response.data.message));
        //   } else {
        //     dispatch(openErrorModal(err.response.status));
        //   }
        // } else if (err.request) {
        //   dispatch(openErrorModal(err.request.data || err.message));
        // } else {
        //   dispatch(openErrorModal(err.message));
        // }
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

export const verifyProfilePasswordUpdate = () => (dispatch: AppDispatch) => {
  dispatch(setLoading(true));

  return API.verifyProfilePasswordUpdate()
    .then(response => {
      dispatch(setOtpTimestamp(response.data.otp_expiration_timestamp));
      dispatch(openModal('verifyProfilePasswordUpdate'));
    })
    .catch(err => {
      if (err.response?.data) {
        dispatch(setError(err.response.data.message as string));
      } else {
        dispatch(setError(err.message));
      }
      // if (err.response) {
      //   if (typeof err.response.data.message === 'string') {
      //     dispatch(openErrorModal(modalContentTexts.error.text));
      //   } else {
      //     dispatch(openErrorModal(modalContentTexts.error.text));
      //   }
      // } else if (err.request) {
      //   dispatch(openErrorModal(modalContentTexts.error.text));
      // } else {
      //   dispatch(openErrorModal(modalContentTexts.error.text));
      // }
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
};

export const resetPasswordFromProfile =
  (payload: { code: string; password: string }) => (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    return API.resetPasswordFromProfile(payload)
      .then(res => {
        dispatch(
          openInfoModal({
            title: 'Success',
            message: 'Password changed successfully',
          })
        );
      })
      .catch(err => {
        if (err.response?.data) {
          dispatch(setError(err.response.data.message as string));
        } else {
          dispatch(setError(err.message));
        }
        // if (err.response) {
        //   if (typeof err.response.data.message === 'string') {
        //     dispatch(openErrorModal(err.response.data.message));
        //   } else {
        //     dispatch(openErrorModal(err.response.status));
        //   }
        // } else if (err.request) {
        //   dispatch(openErrorModal(err.request.data || err.message));
        // } else {
        //   dispatch(openErrorModal(err.message));
        // }
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

export const getResume = (fileName: string) => (dispatch: AppDispatch) => {
  dispatch(setLoading(true));

  return API.getResume()
    .then(response => {
      downloadPDFBlob(response.data, fileName);
    })
    .catch(err => {
      if (err.response) {
        if (typeof err.response.data.message === 'string') {
          dispatch(openErrorModal(modalContentTexts.error.text));
        } else {
          dispatch(openErrorModal(modalContentTexts.error.text));
        }
      } else if (err.request) {
        dispatch(openErrorModal(modalContentTexts.error.text));
      } else {
        dispatch(openErrorModal(modalContentTexts.error.text));
      }
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
};

export const deleteUserProfile = () => (dispatch: AppDispatch) => {
  dispatch(setLoading(true));

  return API.deleteUserProfile()
    .then(response => {
      // console.log(response);
      dispatch(setToken(null));
      dispatch(setUserPhoneNumber(''));
      dispatch(closeModal());
    })
    .catch((err: AxiosError<{ message: string }>) => {
      if (err.response?.data) {
        dispatch(setError(err.response.data.message as string));
      } else {
        dispatch(setError(err.message));
      }
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
};
