import { combineReducers, configureStore } from '@reduxjs/toolkit';
import WebContentReducer from './reducers/ui/WebContentSlice';
import drawerReducer from './reducers/ui/DrawerSlice';
import modalDialogReducer from './reducers/ui/ModalDialogSlice';
import UIHandlerReducer from './reducers/ui/HandlerSlice';
import userTokenReducer from './reducers/api/UserTokenSlice';
import userInformationReducer from './reducers/api/UserInformationSlice';
import subscriptionPackagesReducer from './reducers/ui/SubscriptionPackagesSlice';
import postUserJobReducer from './reducers/api/PostUserJobSlice';
import UserInvoicesReducer from './reducers/api/UserInvoicesSlice';
import UserManageJobsReducer from './reducers/api/UserManageJobsSlice';
import candidateAssignments from './reducers/api/CandidateAssignmentsSlice';
import adminAssignments from './reducers/api/AdminAssignmentSlice';
import adminInvoices from './reducers/api/AdminInvoicesSlice';

const rootReducer = combineReducers({
  userTokenReducer,
  WebContentReducer,
  userInformationReducer,
  drawerReducer,
  modalDialogReducer,
  UIHandlerReducer,
  subscriptionPackagesReducer,
  postUserJobReducer,
  UserManageJobsReducer,
  UserInvoicesReducer,
  candidateAssignments,
  adminAssignments,
  adminInvoices,
});

export const store = configureStore({
  reducer: rootReducer,
});

export const setupStore = () => {
  return store;
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
