import React, { useEffect, useState } from 'react';
import styles from './_styles.module.scss';
import JobDetailsMobContainer from '../../../../../containers/JobDetailsMobContainer/JobDetailsMobContainer';
import JobHeading from '../../../../Candidate/JobDetails/JobHeading';
import JobCardDetails from '../../../../Candidate/JobDetails/JobCardDetails/JobCardDetails';
import JobTypeAndDate from '../../../../JobTypeAndDate/JobTypeAndDate';
import { IAssignmentDetails } from '../../../../../core/models/models';
import { useParams } from 'react-router-dom';
import { setLoading } from '../../../../../redux/reducers/ui/HandlerSlice';
import JobDetailsQualification from '../../../../Candidate/JobDetails/JobDetailsQualification';
import JobDetailsDescription from '../../../../Candidate/JobDetails/JobDetailsDescription';
import { useAppDispatch } from '../../../../../core/hooks/reduxHooks';
import { getAssignmentJobDetailsAxios } from '../../../../../core/services/axios';

const ViewPendingApprovalJobMob = () => {
  const [isJobDetails, setJobDetails] = useState<
    IAssignmentDetails | undefined
  >(undefined);
  const dispatch = useAppDispatch();

  const { jobId } = useParams();

  useEffect(() => {
    if (jobId) {
      dispatch(setLoading(true));
      try {
        getAssignmentJobDetailsAxios(jobId).then(res => {
          setJobDetails(res);
        });
        dispatch(setLoading(false));
      } catch (error) {
        dispatch(setLoading(false));
      }
    }
  }, [jobId]);

  if (isJobDetails) {
    return (
      <JobDetailsMobContainer>
        <section className={styles.details}>
          <div className={styles.details_wrap}>
            <div className={styles.details_header}>
              <JobHeading heading={isJobDetails.title} size={20} />
              <JobCardDetails
                companyName={isJobDetails.company_name}
                location={isJobDetails.location}
                lowestRate={isJobDetails.lowest_rate}
                highestRate={isJobDetails.highest_rate}
                expectedSalaryDivider={isJobDetails.expected_salary_divider}
                time={isJobDetails.time}
              />
              <JobTypeAndDate
                date={isJobDetails.created_at}
                type={isJobDetails.job_type}
              />
            </div>
          </div>
          <div className={styles.details_wrap}>
            <div className={styles.details_qualification}>
              <JobDetailsQualification
                industry={isJobDetails.category}
                qualifications={isJobDetails.preference}
              />
            </div>
          </div>
          <div className={styles.details_wrap}>
            <div className={styles.description}>
              <JobDetailsDescription description={isJobDetails.description} />
            </div>
          </div>
        </section>
      </JobDetailsMobContainer>
    );
  } else {
    return (
      <JobDetailsMobContainer>
        <section className={styles.details}></section>
      </JobDetailsMobContainer>
    );
  }
};

export default ViewPendingApprovalJobMob;
