import React, { FC } from 'react';
import './JobCardDetails.scss';
import LocationWithLinks from '../../../LocationWithLinks/LocationWithLinks';

interface JobCardDetailsProps {
  companyName?: string;
  location: string;
  locationLinks?: { [Key in string]: string };
  lowestRate: number | null;
  highestRate: number | null;
  expectedSalaryDivider: string;
  null_rate_message?: string;
  time?: string;
  fullDetails?: boolean;
}

const JobCardDetails: FC<JobCardDetailsProps> = ({
  companyName,
  location,
  lowestRate,
  highestRate,
  expectedSalaryDivider,
  null_rate_message = 'Please propose rate',
  time,
  fullDetails,
  locationLinks,
}) => {
  const rateMessage =
    lowestRate === 0 && highestRate === 0
      ? `${null_rate_message}`
      : lowestRate === highestRate
      ? `$${lowestRate || highestRate} / ${expectedSalaryDivider || 'month'}`
      : lowestRate && highestRate
      ? `$${lowestRate}-${highestRate} / ${expectedSalaryDivider || 'month'}`
      : lowestRate || highestRate
      ? `$${lowestRate} / ${expectedSalaryDivider || 'month'}`
      : '-';

  return (
    <div className='job-details__card'>
      <div className='job-details__info info'>
        {companyName ? (
          <div className='info__company info--item'>
            <div className='info__company--icon info__icon' />
            <p>{companyName}</p>
          </div>
        ) : null}
        <div className='info__location info--item'>
          <div className='info__location--icon info__icon' />
          {fullDetails && locationLinks ? (
            <LocationWithLinks location={location} links={locationLinks} />
          ) : (
            <p>{location}</p>
          )}
        </div>
        <div className='info__price info--item'>
          <div className='info__price--icon info__icon' />
          <p>{rateMessage}</p>
        </div>
        {time ? (
          <div className='info__time info--item'>
            <div className='info__time--icon info__icon' />
            <p>{time}</p>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default JobCardDetails;
