import { createSlice, PayloadAction } from '@reduxjs/toolkit';
interface UserTokenState {
  token: string | null;
  refresh_token: string | null;
  isAdmin: boolean;
  isLoading: boolean;
  error: string;
  hp_number: string;
  afterRegister: boolean;
  passwordTemp: string;
  verifyEmailMessage: string;
}

const initialState: UserTokenState = {
  token: null,
  refresh_token: null,
  isAdmin: false,
  isLoading: false,
  error: '',
  hp_number: '',
  afterRegister: false,
  passwordTemp: '',
  verifyEmailMessage: '',
};

export const userTokenSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setUserPhoneNumber(state, action: PayloadAction<string>) {
      state.hp_number = action.payload;
    },
    setAdminRole(state, action: PayloadAction<boolean>) {
      state.isAdmin = action.payload;
    },
    setToken(state, action: PayloadAction<string | null>) {
      state.token = action.payload;
    },
    setRefreshToken(state, action: PayloadAction<string | null>) {
      state.refresh_token = action.payload;
    },
    setError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    clearAfterRegister(state) {
      state.afterRegister = false;
    },
    setPasswordTemp(state, action: PayloadAction<string>) {
      state.passwordTemp = action.payload;
    },
    setVerifyEmailMessage(state, { payload }: PayloadAction<string>) {
      state.verifyEmailMessage = payload;
    },
  },
});

export const {
  setUserPhoneNumber,
  setAdminRole,
  setToken,
  setRefreshToken,
  setError,
  clearAfterRegister,
  setPasswordTemp,
  setVerifyEmailMessage,
} = userTokenSlice.actions;

export default userTokenSlice.reducer;
