import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import useOnClickOutside from '../../../core/hooks/useOnClickOutside';
import './UISelect.scss';
import cn from 'classnames';
import UISelectCheckbox from '../UISelectCheckbox/UISelectCheckbox';
import { IAvailableSubscription } from '../../../core/models/models';

type UISelectProps = {
  list: IAvailableSubscription[];
  selectedOption: IAvailableSubscription;
  heading: string;
  setSelectedOption: (option: IAvailableSubscription) => void;
  className?: string;
};

const UISelectSubscription: FC<UISelectProps> = ({
  list,
  selectedOption,
  heading,
  setSelectedOption,
  className,
}) => {
  const [isListOpen, setListOpen] = useState<boolean>(false);
  const selectBoxRef = useRef<HTMLDivElement>(null);

  const closeDropdown = useCallback(() => setListOpen(false), []);

  useOnClickOutside(selectBoxRef, closeDropdown);

  useEffect(() => {}, [list]);

  const handleSort = useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement>,
      option: IAvailableSubscription
    ) => {
      setSelectedOption(option);
      setListOpen(false);
    },
    [selectedOption, list, setSelectedOption]
  );
  //
  // useEffect(() => {}, [selectedOption, list, setSelectedOption]);

  return (
    <div className={`dropdown ${className && className}`} ref={selectBoxRef}>
      <p className='dropdown__heading'>{heading}</p>
      <div className='dropdown__wrapper'>
        <button
          type='button'
          className='dropdown__header'
          onClick={() => setListOpen(prev => !prev)}
        >
          <p className={'heading'}>{selectedOption.handle}</p>
          <div className={'dropdown__icons'}>
            <span
              className={cn({
                dropdown__arrow: true,
                'dropdown__arrow--up': isListOpen,
              })}
            />
          </div>
        </button>
        <ul
          className={cn({
            dropdown__list: true,
            'dropdown__list--is-open': isListOpen,
          })}
        >
          {list.map((option, key) => (
            <li key={key}>
              <UISelectCheckbox
                key={option.subscription_id}
                id={option.subscription_id}
                type='radio'
                title={option.handle}
                checked={
                  selectedOption.subscription_id === option.subscription_id
                }
                onChange={e => handleSort(e, option)}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default UISelectSubscription;
