export const formatDate = (date: string | Date) =>
  (date instanceof Date ? date : new Date(date)).toLocaleDateString('uk-UA');

export const getDateFromLocaleString = (date: string) => {
  const [day, month, year] = date.split('.').map(Number);

  return new Date(year, month - 1, day);
};

export const getCountDownDate = (timestamp: number) => {
  // Set the date we're counting down to
  return new Date(timestamp * 1000).getTime();
};

export const getDistance = (time: number) => {
  // Get today's date and time
  const now = new Date().getTime();
  // Find the distance between now and the count-down date
  return time - now;
};

export const getSeconds = (miliseconds: number) => {
  return Math.floor((miliseconds) / 1000);
};

export const getTimerView = (seconds: number) => {
  // Time calculations for minutes and seconds
  return new Date(seconds * 1000).toISOString().substring(14, 19);
};

export const getDate = (date: string | null): Date => {
  if (date) {
    if (date.includes('-')) {
      const [year, month, day] = date.split('-');

      return new Date(+year, +month - 1, +day);
    } else {
      const [day, month, year] = date.split('/');

      return new Date(+year, +month - 1, +day);
    }
  } else {
    return new Date();
  }
};

export const getStringDate = (date: Date): string => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};