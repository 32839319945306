import React, { FC } from 'react';
import styles from './_styles.module.scss';
import UIModalDialogHeader from '../../UI/UIModalDialog/UIModalDialogHeader';
import UIButton from '../../UI/UIButton/UIButton';
import { useAppDispatch } from '../../../core/hooks/reduxHooks';
import { closeModal } from '../../../redux/reducers/ui/ModalDialogSlice';
import TGForwardLink from '../../UI/TGForwardLink/TGForwardLink';
import { tgChannels } from '../../../core/constants/links';
import useWebContent from '../../../core/hooks/useWebContent';

const SuccessfullyApplyModal: FC = () => {
  const dispatch = useAppDispatch();
  const [webContent] = useWebContent();

  return (
    <div className={styles.modal_success}>
      <UIModalDialogHeader />
      <div className={styles.modal_success_content}>
        <h3 className={styles.heading}>{webContent.apply_modal_title_web}</h3>
        <p className={styles.text}>{webContent.apply_modal_body_web}</p>
        <div className={styles.channels}>
          <TGForwardLink
            urlTg={webContent.all_posts_channel_link_web}
            title={webContent.all_posts_channel_web}
          />{' '}
          <TGForwardLink
            urlTg={webContent.part_time_posts_channel_link_web}
            title={webContent.part_time_posts_channel_web}
          />
          <TGForwardLink
            urlTg={webContent.full_time_posts_channel_link_web}
            title={webContent.full_time_posts_channel_web}
          />
        </div>
        <UIButton
          title={'Close'}
          callback={() => dispatch(closeModal())}
          classModificator={styles.back_btn}
        />
      </div>
    </div>
  );
};

export default SuccessfullyApplyModal;
