import React, { FC } from 'react';
import styles from './_styles.module.scss';

const UIReviewCandidateQuality: FC<{
  title: string;
  whatsapp?: string;
  quality?: string | null;
  experience?: string[];
}> = ({ title, quality, whatsapp, experience }) => {
  return (
    <div className={`${styles.quality} ${experience && styles.experience}`}>
      <b>{title}:</b>
      {whatsapp && (
        <a className={styles.whatsapp_link} title={'Whatsapp'} href={whatsapp}>
          {quality}
        </a>
      )}
      {experience ? (
        <p>{experience.join(`\r\n`) || 'Not specified'}</p>
      ) : (
        <>{!whatsapp && (quality || 'Not specified')}</>
      )}
    </div>
  );
};

export default UIReviewCandidateQuality;
