import React, { useEffect } from 'react';
import styles from '../_styles.module.scss';
import UIModalDialogHeader from '../../UI/UIModalDialog/UIModalDialogHeader';
import UIButton from '../../UI/UIButton/UIButton';
import { closeModal } from '../../../redux/reducers/ui/ModalDialogSlice';
import { useAppDispatch, useAppSelector } from '../../../core/hooks/reduxHooks';
import {
  approveAdminAssignment,
  getAdminAssignmentById,
  updateAdminAssignment,
} from '../../../redux/thunk/adminAssignments';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  adminAssignmentsPages,
  adminAssignmentsTypes,
} from '../../../core/constants/api';
import { AdminAssignmentsType } from '../../../core/models/models';
import { dark } from '@mui/material/styles/createPalette';

const AssignmentBannedContent = () => {
  const bannedAssignmentId = useAppSelector(
    state => state.modalDialogReducer.bannedAssignmentId
  );
  const updateJobAssignmentId = useAppSelector(
    state => state.adminAssignments.form.assignmentId
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <div className={`${styles.modal} ${styles.banned_content_modal}`}>
      <UIModalDialogHeader heading={'Banned content'} />
      <div className={styles.modal_body}>
        <p className={styles.message}>Assignment contains banned content</p>
        <div className={styles.buttons}>
          <UIButton
            title={'Back'}
            callback={() => dispatch(closeModal())}
            classModificator={styles.back}
          />
          {bannedAssignmentId && !updateJobAssignmentId ? (
            <UIButton
              title={'Post job'}
              callback={() =>
                dispatch(approveAdminAssignment(bannedAssignmentId, true))
              }
              classModificator={styles.post_btn}
            />
          ) : updateJobAssignmentId ? (
            <UIButton
              title={'Update job'}
              callback={() => {
                const assignmentTypeSearchParam = searchParams.get(
                  'assignmentType'
                ) as AdminAssignmentsType;
                const isAssignmentTypeSearchParamValid =
                  adminAssignmentsTypes.includes(assignmentTypeSearchParam);

                dispatch(updateAdminAssignment(true)).then(data => {
                  const { message, banned, assignment_id } = data;

                  if (banned === false) {
                    dispatch(
                      getAdminAssignmentById(
                        assignment_id,
                        isAssignmentTypeSearchParamValid
                          ? assignmentTypeSearchParam
                          : undefined
                      )
                    ).then(() => {
                      if (isAssignmentTypeSearchParamValid) {
                        navigate(
                          `/admin/jobs${adminAssignmentsPages[assignmentTypeSearchParam]}`
                        );
                      }
                    });
                  }
                });
              }}
              classModificator={styles.post_btn}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default AssignmentBannedContent;
