import React, { FC } from 'react';
import PurchaseHistory from '../../components/Employer/PurchaseHistory/PurchaseHistory';
import RequireAuth from '../../containers/hoc/RequireAuth';

const EmployerPurchaseHistoryPage: FC = () => {
  return (
    <RequireAuth role={'candidate'}>
      <PurchaseHistory />
    </RequireAuth>
  );
};

export default EmployerPurchaseHistoryPage;
