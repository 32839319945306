import React, { FC, useCallback, useEffect, useRef } from 'react';
import { IUserManageJobResult } from '../../../../core/models/models';
import styles from './_styles.module.scss';
import JobHeading from '../../../Candidate/JobDetails/JobHeading';
import JobCardDetails from '../../../Candidate/JobDetails/JobCardDetails/JobCardDetails';
import JobStatus from '../../../UI/JobStatus/JobStatus';
import JobTypeAndDate from '../../../JobTypeAndDate/JobTypeAndDate';

const ManageJobsList: FC<{
  jobsList: IUserManageJobResult[];
  selectedJobId: string;
  onClick: (id: string) => void;
}> = ({ jobsList, selectedJobId, onClick }) => {
  const jobsListRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const node = jobsListRef.current;

    node?.scroll({
      top: 0,
      behavior: 'smooth',
    });
  }, [jobsList]);

  const jobCard = useCallback(
    (job: IUserManageJobResult) => {
      return (
        <div
          key={job.assignment_id}
          className={`${styles.card} ${
            selectedJobId === job.assignment_id ? styles.card_active : ''
          }`}
          onClick={() => onClick(job.assignment_id)}
        >
          <div className={styles.card_info}>
            <JobHeading heading={job.title} size={18} />
            <JobCardDetails
              companyName={job.company_name}
              location={job.location}
              lowestRate={job.lowest_rate}
              highestRate={job.highest_rate}
              expectedSalaryDivider={job.expected_salary_divider}
              time={job.time}
            />
          </div>
          <div className={styles.footer}>
            <JobStatus status={job.status} className={styles.status} />
            <JobTypeAndDate date={job.created_at} type={job.job_type} />
          </div>
        </div>
      );
    },
    [selectedJobId]
  );

  return (
    <div className={styles.jobs_list} ref={jobsListRef}>
      {jobsList.map(job => jobCard(job))}
    </div>
  );
};

export default ManageJobsList;
