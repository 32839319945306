import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../redux/store';

export const selectSelectedJob = createSelector(
  (state: RootState) => state.candidateAssignments.selectedJob,
  selectedJob => selectedJob
);

export const selectTab = createSelector(
  (state: RootState) => state.candidateAssignments.tab,
  tab => tab
);

export const selectAssignments = createSelector(
  (state: RootState) => state.candidateAssignments.assignments,
  assignments => assignments
);

export const isAssignmentsLoading = createSelector(
  (state: RootState) => state.candidateAssignments.isJobsLoading,
  isJobsLoading => isJobsLoading
);

export const selectActiveFilters = createSelector(
  (state: RootState) => state.candidateAssignments.activeFilters,
  activeFilters => activeFilters
);

export const selectPossibleFilters = createSelector(
  (state: RootState) => state.candidateAssignments.possibleFilters,
  possibleFilters => possibleFilters
);

export const selectTotalPages = createSelector(
  (state: RootState) => state.candidateAssignments.totalPages,
  totalPages => totalPages
);

export const selectCurrentPage = createSelector(
  (state: RootState) => state.candidateAssignments.selectedPage,
  selectedPage => selectedPage
);

export const selectToken = createSelector(
  (state: RootState) => state.userTokenReducer.token,
  token => token
);
