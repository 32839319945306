import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import RequireAuth from '../../containers/hoc/RequireAuth';

const EmployerManageJobsPage: FC = () => {
  return (
    <>
      <RequireAuth role={'employer'}>
        <Outlet />
      </RequireAuth>
    </>
  );
};

export default EmployerManageJobsPage;
