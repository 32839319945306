import React, { FC, useEffect } from 'react';
import { useRouter } from '../../core/hooks/useRouter';
import { Navigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../core/hooks/reduxHooks';
import { openModal } from '../../redux/reducers/ui/ModalDialogSlice';

const RequireAuth: FC<{ role: 'candidate' | 'employer' }> = ({
  role,
  children,
}) => {
  const { location } = useRouter();
  const { token } = useAppSelector(state => state.userTokenReducer);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!token) {
      dispatch(openModal('login'));
    }
  }, [token]);

  if (!token) {
    return (
      <Navigate
        to={role === 'candidate' ? '/' : '/employer'}
        state={{ form: location }}
      />
    );
  }

  return <> {children} </>;
};

export default RequireAuth;
