import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IModalsType } from '../../../core/models/models';

interface ModalDialogState {
  isOpened: boolean;
  modal: IModalsType;
  // type: 'component' | 'confirm' | 'result' | null;
  errorMessage: string;
  infoMessage: {
    title?: string;
    message: string;
  };
  purchaseCredit: 'bump' | 'shortlist';
  bannedAssignmentId: string;
  applyingAssignmentId: string;
  misc: {
    otpTimestamp: number | null;
  };
}

const initialState: ModalDialogState = {
  isOpened: false,
  modal: 'login',
  errorMessage: '',
  infoMessage: {
    title: '',
    message: '',
  },
  purchaseCredit: 'bump',
  bannedAssignmentId: '',
  applyingAssignmentId: '',
  misc: {
    otpTimestamp: null,
  },
};

export const modalDialogSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state, { payload }: PayloadAction<IModalsType>) => {
      state.isOpened = true;
      state.modal = payload;
    },
    openApplyingModal: (state, { payload }: PayloadAction<string>) => {
      state.isOpened = true;
      state.modal = 'applying';
      state.applyingAssignmentId = payload;
    },
    openInfoCreditsModal: (
      state,
      { payload }: PayloadAction<'bump' | 'shortlist'>
    ) => {
      state.isOpened = true;
      state.modal = 'purchase_credit_bump_or_shortlist';
      state.purchaseCredit = payload;
    },
    closeModal: state => {
      state.isOpened = false;
      state.bannedAssignmentId = '';
    },
    openBannedContentModal: (state, { payload }: PayloadAction<string>) => {
      state.isOpened = true;
      state.modal = 'banned content';
      state.bannedAssignmentId = payload;
    },
    openErrorModal: (state, { payload }: PayloadAction<string>) => {
      state.isOpened = true;
      state.modal = 'error';
      state.errorMessage = payload;
    },
    clearErrorMessage: state => {
      state.errorMessage = '';
    },
    openInfoModal: (
      state,
      { payload }: PayloadAction<{ title?: string; message: string }>
    ) => {
      state.isOpened = true;
      state.modal = 'info';
      state.infoMessage.title = payload.title || '';
      state.infoMessage.message = payload.message;
    },
    setOtpTimestamp: (state, { payload }: PayloadAction<number>) => {
      state.misc.otpTimestamp = payload | 0;
    },
  },
});

export const {
  openModal,
  openApplyingModal,
  closeModal,
  openBannedContentModal,
  openErrorModal,
  clearErrorMessage,
  openInfoModal,
  openInfoCreditsModal,
  setOtpTimestamp,
} = modalDialogSlice.actions;

export default modalDialogSlice.reducer;
