import React, {
  FormEventHandler,
  useCallback,
  useEffect,
  useState,
} from 'react';
import UIModalDialogHeader from '../../UI/UIModalDialog/UIModalDialogHeader';
import styles from './_styles.module.scss';
import UILoginPass from '../../UI/UILogin/UILoginPass';
import { useLocation } from 'react-router-dom';
import UIButton from '../../UI/UIButton/UIButton';
import { useAppDispatch, useAppSelector } from '../../../core/hooks/reduxHooks';
import { setPasswordTemp } from '../../../redux/reducers/api/UserTokenSlice';
import { resetPasswordFromLogin } from '../../../redux/thunk/login';
import { verifyProfilePasswordUpdate } from '../../../redux/thunk/profile';

const UpdatePassword: React.FC<{ type: 'login' | 'profile' }> = ({ type }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { passwordTemp, hp_number } = useAppSelector(
    state => state.userTokenReducer
  );
  const [repeatPassword, setRepeatPassword] = useState('');
  const [error, setError] = useState('');

  const submitPasswordUpdate: FormEventHandler = event => {
    event.preventDefault();

    if (passwordTemp !== repeatPassword) {
      setError('Passwords do not match');

      return;
    } else if (!passwordTemp.length && !repeatPassword.length) {
      setError('Please fill all fields');

      return;
    }

    switch (type) {
      case 'login':
        dispatch(resetPasswordFromLogin(hp_number));
        break;
      case 'profile':
        dispatch(verifyProfilePasswordUpdate());
        break;
    }
  };

  useEffect(() => {
    dispatch(setPasswordTemp(''));
  }, []);

  return (
    <form onSubmit={submitPasswordUpdate}>
      <UIModalDialogHeader heading='new password' />
      <div className={styles.password_modal}>
        <div className={styles.password_fields}>
          <UILoginPass
            idInput={'main'}
            color={'secondary'}
            value={passwordTemp}
            onChange={e => {
              if (error.length) {
                setError('');
              }

              dispatch(setPasswordTemp(e.target.value.replace(/\s/g, '')));
            }}
            error={!!error.length}
          />
          <UILoginPass
            idInput={'secondary'}
            color={'secondary'}
            value={repeatPassword}
            repeat
            onChange={e => {
              if (error.length) {
                setError('');
              }

              setRepeatPassword(e.target.value.replace(/\s/g, ''));
            }}
            error={!!error.length}
          />
        </div>
        {error && <span className={styles.error}>{error}</span>}
        <UIButton title='Submit' type='submit' />
      </div>
    </form>
  );
};

export default UpdatePassword;
