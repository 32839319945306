import React, { FC } from 'react';
import './ProfileFormGroup.scss';

interface ProfileFormGroupProps {
  heading: string;
  className?: string;
  required?: boolean;
  error?: boolean;
}

const ProfileFormGroup: FC<ProfileFormGroupProps> = ({
  children,
  heading,
  className,
  required,
  error,
}) => {
  return (
    <div className={`profile__from form ${className || ''}`} id={heading}>
      <span
        className='form__heading'
        style={{ color: error ? '#f32a2a' : '#35435e' }}
      >
        {heading}
        {required && (
          <span
            style={{
              color: error ? '#f32a2a' : '#35435e',
            }}
          >
            *
          </span>
        )}
      </span>
      <ul className='form__fields--list'>{children}</ul>
    </div>
  );
};

export default ProfileFormGroup;
