import React, { useCallback, useEffect, useRef } from 'react';
import AssignmentCard from '../../../components/AssignmentCard';
import AssignmentInfo from '../../../components/AssignmentInfo';
import SearchInput from '../../../components/UI/SearchInput';
import UIButton from '../../../components/UI/UIButton/UIButton';
import UIFilterSelectBox from '../../../components/UI/UIFilterSelectBox/UIFilterSelectBox';
import UIPagination from '../../../components/UI/UIPagination/UIPagination';
import AssignmentsList from '../../../containers/AssignmentsList';
import { adminAssignmentsOrders } from '../../../core/constants/api';
import { useAppDispatch, useAppSelector } from '../../../core/hooks/reduxHooks';
import {
  setCurrentAssignment,
  setOrderBy,
  setPagination,
  setSearch,
} from '../../../redux/reducers/api/AdminAssignmentSlice';
import {
  approveAdminAssignment,
  deleteAdminAssignment,
  getAdminAssignmentById,
  getAdminAssignments,
  rejectAdminAssignment,
} from '../../../redux/thunk/adminAssignments';
import style from './_index.module.scss';
import { AdminAssignmentsType } from '../../../core/models/models';

interface ViewAssignmentsProps {
  assignmentsType: AdminAssignmentsType;
}

const EmptyView: React.FC = () => (
  <div className={style.empty}>
    <h3 style={{ textAlign: 'center' }}>Jobs not published yet</h3>
  </div>
);

const ViewAssignments: React.FC<ViewAssignmentsProps> = ({
  assignmentsType,
}) => {
  const {
    current,
    assignments,
    pagination: { pageNumber, pageSize, totalPages, total },
    orderBy,
    search,
  } = useAppSelector(state => state.adminAssignments[assignmentsType]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      getAdminAssignments(assignmentsType, {
        order_by: orderBy,
        // ...(search && { job_code: search }),
        page_number: pageNumber,
        page_size: pageSize,
      })
    );
  }, [assignmentsType, orderBy, pageNumber, pageSize, dispatch]);

  const jobsListRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const node = jobsListRef.current;

    node?.scroll({
      top: 0,
      behavior: 'smooth',
    });
  }, [assignments]);

  if (!assignments.length) {
    return <EmptyView />;
  }

  return (
    <div className={style.container}>
      <div className={style.left_menu}>
        <SearchInput
          value={search}
          onChange={({ target: { value } }) =>
            dispatch(setSearch([assignmentsType, value]))
          }
          onSearch={value => {
            if (value) {
              dispatch(getAdminAssignmentById(value, assignmentsType));
            }
          }}
          containerClassName={style.search}
        />
        <UIFilterSelectBox
          list={adminAssignmentsOrders}
          selectedOption={orderBy}
          setSelectedOption={option =>
            dispatch(setOrderBy([assignmentsType, option]))
          }
          className={style.orderBy}
        />
        {!!assignments.length && (
          <div>
            <AssignmentsList assignmentsType={assignmentsType} />
            {totalPages > 1 && (
              <UIPagination
                currentPage={pageNumber}
                totalPages={totalPages}
                onChangePage={(_, page) =>
                  dispatch(
                    setPagination([assignmentsType, { pageNumber: page }])
                  )
                }
                siblingCount={1}
                boundaryCount={1}
                className={style.pagination}
              />
            )}
          </div>
        )}
      </div>
      <div className={style.assignmentContainer}>
        <AssignmentInfo assignmentType={assignmentsType} assignment={current} />
        {!!current &&
          (assignmentsType === 'on_review' ||
            assignmentsType === 'pending' ||
            assignmentsType === 'content_review') && (
            <div className={style.buttons}>
              {/* <UIButton
                title='Delete'
                classModificator={`${style.delete} ${style.btn}`}
                callback={() =>
                  dispatch(deleteAdminAssignment(current.assignment_id)).then(
                    ({ status }) => {
                      if (status === 'success') {
                        dispatch(setCurrentAssignment([assignmentsType, null]));
                        dispatch(
                          getAdminAssignments(assignmentsType, {
                            order_by: orderBy,
                            // ...(search && { job_code: search }),
                            page_number: pageNumber,
                            page_size: pageSize,
                          })
                        );
                      }
                    }
                  )
                }
              /> */}
              <UIButton
                title='Reject job'
                classModificator={`${style.reject} ${style.btn}`}
                callback={() => {
                  dispatch(rejectAdminAssignment(current.assignment_id)).then(
                    () => {
                      dispatch(setCurrentAssignment([assignmentsType, null]));
                      dispatch(
                        getAdminAssignments(assignmentsType, {
                          order_by: orderBy,
                          // ...(search && { job_code: search }),
                          page_number: pageNumber,
                          page_size: pageSize,
                        })
                      );
                    }
                  );
                }}
              />
              <UIButton
                title='Approve job'
                classModificator={`${style.approve} ${style.btn}`}
                callback={() =>
                  dispatch(
                    approveAdminAssignment(
                      current.assignment_id,
                      assignmentsType === 'content_review',
                      assignmentsType === 'pending'
                    )
                  ).then(() => {
                    dispatch(setCurrentAssignment([assignmentsType, null]));
                    dispatch(
                      getAdminAssignments(assignmentsType, {
                        order_by: orderBy,
                        // ...(search && { job_code: search }),
                        page_number: pageNumber,
                        page_size: pageSize,
                      })
                    );
                  })
                }
              />
            </div>
          )}
      </div>
    </div>
  );
};

export default ViewAssignments;
