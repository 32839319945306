/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IUserInformation,
  IUserInformationInitial,
} from '../../../core/models/models';

interface UserInformationSliceState {
  information: IUserInformationInitial;
  employerInformation: IUserInformationInitial;
  complete: boolean;
  requiredFields: string[] | null;
  isLoadingUpdateProfile: boolean;
  error: string;
}

type ChosenKeys =
  | 'birthDate'
  | 'hpNumber'
  | 'name'
  | 'email'
  | 'highestQualification'
  | 'detailsOfExperience'
  | 'chosenRace'
  | 'chosenCitizenship'
  | 'chosenGroup'
  | 'chosenGender'
  | 'chosenResume'
  | 'years_working';

type ChosenArrays =
  | 'chosenJobCategories'
  | 'chosenLocations'
  | 'chosenNotifications'
  | 'chosenPreferredJobTypes';

const initialState: UserInformationSliceState = {
  information: {
    required_fields: [],
    initialEmail: '',
    chosenResumeMaxFileSize: 2000000,
    profilePictureMaxSize: 10000000,
  },
  employerInformation: {
    required_fields: [],
    initialEmail: '',
    chosenResumeMaxFileSize: 2000000,
    profilePictureMaxSize: 10000000,
  },
  complete: true,
  requiredFields: [],
  isLoadingUpdateProfile: false,
  error: '',
};

export const userInformationSlice = createSlice({
  name: 'information',
  initialState,
  reducers: {
    setLoadingUpdateProfile(state, { payload }: PayloadAction<boolean>) {
      state.isLoadingUpdateProfile = payload;
    },
    setCandidateInformation(
      state,
      { payload }: PayloadAction<IUserInformationInitial>
    ) {
      const payloadInformation = (({ required_fields, ...rest }) => rest)(
        payload
      );

      // @ts-ignore
      state.information = {
        ...payloadInformation,
        initialEmail:
          payloadInformation.verified_email || payloadInformation.email,
      };
      state.requiredFields = payload.required_fields;
    },
    setEmployerInformation(
      state,
      { payload }: PayloadAction<IUserInformationInitial>
    ) {
      state.employerInformation = { ...payload, initialEmail: payload.email };
    },
    clearLogoutUserInfo(state) {
      state.employerInformation = initialState.employerInformation;
      state.information = initialState.information;
    },
    setChosenValue(
      state,
      {
        payload: { key, value },
      }: PayloadAction<{ key: ChosenKeys; value: string | null }>
    ) {
      state.information[key] = value as never;
    },
    setEmployerChosenValue(
      state,
      {
        payload: { key, value },
      }: PayloadAction<{ key: ChosenKeys; value: string | null }>
    ) {
      state.employerInformation[key] = value as never;
    },
    setUserPhoto(state) {
      state.information.profilePictureComplete = true;
    },
    setChosenJobCategory(
      state,
      {
        payload: { value, add },
      }: PayloadAction<{ value: string; add: boolean }>
    ) {
      if (value === 'Any category') {
        if (add) {
          state.information.chosenJobCategories =
            state.information.jobCategories;
        } else {
          state.information.chosenJobCategories = [];
        }
      } else if (add) {
        state.information.chosenJobCategories?.push(value as never);
      } else {
        state.information.chosenJobCategories?.splice(
          state.information.chosenJobCategories.indexOf(value as never),
          1
        );

        if (state.information.chosenJobCategories?.includes('Any category')) {
          state.information.chosenJobCategories?.splice(
            state.information.chosenJobCategories.indexOf('Any category'),
            1
          );
        }
      }
    },
    switchChosenArrayItem(
      state,
      {
        payload: { array, value, add },
      }: PayloadAction<{ array: ChosenArrays; value: string; add: boolean }>
    ) {
      if (add) {
        // @ts-ignore
        state.information[array].push(value as never);
      } else {
        // @ts-ignore
        state.information[array].splice(
          // @ts-ignore
          state.information[array].indexOf(value as never),
          1
        );
      }
    },
    switchChosenEmployerNotify(
      state,
      {
        payload: { value, add },
      }: PayloadAction<{ value: string; add: boolean }>
    ) {
      if (add) {
        // @ts-ignore
        state.employerInformation.chosenNotifications.push(value as never);
      } else {
        // @ts-ignore
        state.employerInformation.chosenNotifications.splice(
          // @ts-ignore
          state.employerInformation.chosenNotifications.indexOf(value as never),
          1
        );
      }
    },
    setProfileStatus(
      state,
      {
        payload: { complete, requiredFields },
      }: PayloadAction<{ complete: boolean; requiredFields: string[] }>
    ) {
      state.complete = complete;
      state.requiredFields = requiredFields;
    },
    setVerifyEmail(
      state,
      { payload }: PayloadAction<'employer' | 'candidate'>
    ) {
      if (payload === 'candidate') {
        state.information.initialEmail = state.information.email;
        state.information.is_verified_email = true;
      } else {
        state.employerInformation.initialEmail = state.information.email;
        state.employerInformation.is_verified_email = true;
      }
    },
  },
});

export const {
  setLoadingUpdateProfile,
  setCandidateInformation,
  setEmployerInformation,
  clearLogoutUserInfo,
  setChosenJobCategory,
  setChosenValue,
  setEmployerChosenValue,
  setUserPhoto,
  switchChosenArrayItem,
  switchChosenEmployerNotify,
  setVerifyEmail,
  setProfileStatus,
} = userInformationSlice.actions;

export default userInformationSlice.reducer;
