import React, { useCallback, useEffect, useRef, useState } from 'react';
import SearchCandidateWork from '../../containers/FiltersCandidateWork/SearchCandidateWork';
import styles from './_styles.module.scss';
import SearchWorkCardList from '../../components/Works/SearchWorkCard/SearchWorkCardList';
import SearchWorkCardFullInfo from '../../components/Works/SearchWorkCardFullInfo/SearchWorkCardFullInfo';
import { useWindowSize } from '../../core/hooks/useWindowSize';
import { breakpointForJobsList } from '../../core/constants/variables';
import SearchWorkCardListMob from '../../components/Works/SearchWorkCard/SearchWorkCardListMob';
import SearchWorkFilters from '../../components/Works/SearchWorkFilters/SearchWorkFilters';
import UIPagination from '../../components/UI/UIPagination/UIPagination';
import { useAppDispatch, useAppSelector } from '../../core/hooks/reduxHooks';
import Search from '../../containers/Search/Search';
import {
  getAppliedAssignments,
  getAssignmentsAsync,
  getSavedAssignmentsAsync,
} from '../../redux/thunk/assignments';
import JobsInfoListContainer from '../../containers/JobsInfoListContainer/JobsInfoListContainer';
import {
  isAssignmentsLoading,
  selectActiveFilters,
  selectAssignments,
  selectCurrentPage,
  selectTab,
  selectToken,
  selectTotalPages,
} from '../../core/utils/selectors';
import useSyncSearchAssignments from '../../core/hooks/useSyncSearchAssignments';
import useWebContent from '../../core/hooks/useWebContent';
import { StringParam, useQueryParam } from 'use-query-params';
import { Navigate } from 'react-router-dom';
import { setCurrentPage } from '../../redux/reducers/api/CandidateAssignmentsSlice';
import { CircularProgress } from '@mui/material';

const CandidateSearchPage = () => {
  const dispatch = useAppDispatch();
  const size = useWindowSize();
  const [selectedJobId, setSelectedJobId] = useQueryParam('id', StringParam);

  const [webContent] = useWebContent();

  const tab = useAppSelector(selectTab);
  const assignments = useAppSelector(selectAssignments);
  const isLoading = useAppSelector(isAssignmentsLoading);
  const activeFilters = useAppSelector(selectActiveFilters);
  const totalPages = useAppSelector(selectTotalPages);
  const pageNumber = useAppSelector(selectCurrentPage);
  const token = useAppSelector(selectToken);

  const searchCallback = useCallback(
    (page: number, selectedReplace = true) => {
      if (page !== pageNumber) {
        dispatch(setCurrentPage(page));
      }

      if (tab === 'all') {
        dispatch(
          getAssignmentsAsync(
            {
              ...activeFilters,
              page_number: page,
              page_size: 10,
            },
            selectedReplace
          )
        );
      } else if (tab === 'saved') {
        dispatch(
          getSavedAssignmentsAsync(
            {
              ...activeFilters,
              page_number: page,
              page_size: 10,
            },
            selectedReplace
          )
        );
      } else if (tab === 'applied') {
        dispatch(
          getAppliedAssignments(
            {
              ...activeFilters,
              page_number: page,
              page_size: 10,
            },
            selectedReplace
          )
        );
      }
    },
    [activeFilters, pageNumber, tab]
  );

  useSyncSearchAssignments(searchCallback);

  if (size.width < breakpointForJobsList && selectedJobId) {
    return <Navigate to={`/${selectedJobId}/job-details`} replace={true} />;
  }

  return (
    <>
      <SearchCandidateWork>
        <Search
          searchCallback={selectedReplace => {
            searchCallback(1, selectedReplace);
          }}
        />
      </SearchCandidateWork>
      <section className={styles.available_works}>
        {token && <SearchWorkFilters />}
        {assignments.length ? (
          size.width > breakpointForJobsList ? (
            <JobsInfoListContainer>
              <SearchWorkCardList works={assignments} />
              <SearchWorkCardFullInfo />
              {totalPages > 1 && (
                <UIPagination
                  currentPage={pageNumber}
                  totalPages={totalPages}
                  onChangePage={(event, page) => {
                    dispatch(setCurrentPage(page));
                    searchCallback(page, true);
                  }}
                />
              )}
            </JobsInfoListContainer>
          ) : (
            <>
              <SearchWorkCardListMob assignments={assignments} />
              {totalPages > 1 && (
                <UIPagination
                  currentPage={pageNumber}
                  totalPages={totalPages}
                  onChangePage={(event, page) => {
                    dispatch(setCurrentPage(page));
                    searchCallback(page);
                  }}
                  searchPageMobile={true}
                  size={'small'}
                />
              )}
            </>
          )
        ) : isLoading && assignments.length === 0 ? (
          <CircularProgress className={styles.loading} color={'primary'} />
        ) : (
          <h3
            style={{
              padding: '20px 0 40px',
            }}
          >
            {webContent.no_jobs_web}
          </h3>
        )}
      </section>
    </>
  );
};

export default CandidateSearchPage;
