import React, { FC, useEffect } from 'react';
import UIButtonLink from '../../UI/UIButtonLink/UIButtonLink';
import Logout from '../../Logout/Logout';
import styles from '../_styles.module.scss';
import UIDrawerNavLink from '../../UI/UIDrawerLink/UIDrawerLink';
import { useAppDispatch, useAppSelector } from '../../../core/hooks/reduxHooks';
import { drawerSlice } from '../../../redux/reducers/ui/DrawerSlice';
import { useNavigate } from 'react-router-dom';
import { openModal } from '../../../redux/reducers/ui/ModalDialogSlice';
import {
  setFullTimeFilter,
  setPartTimeFilter,
} from '../../../redux/reducers/api/CandidateAssignmentsSlice';
import UIJobsTypeLink from '../../UI/UIJobsTypeLink/UIJobsTypeLink';
import useWebContent from '../../../core/hooks/useWebContent';

const CandidateDrawer: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const token = useAppSelector(state => state.userTokenReducer.token);
  const { job_types } = useAppSelector(
    state => state.candidateAssignments.activeFilters
  );

  const [webContent] = useWebContent();

  const { closeDrawer } = drawerSlice.actions;

  useEffect(() => {}, [token]);

  const DrawerNotAuthCandidateContent = () => {
    return (
      <>
        <ul className={styles.drawer_list}>
          <li>
            <UIButtonLink
              title={webContent.go_to_job_poster_page_web}
              callback={() => {
                navigate('/employer');
                dispatch(closeDrawer());
              }}
              classModificator={
                'Button__link--for-employers Button__link--startIcon'
              }
            />
          </li>
          <li>
            <UIJobsTypeLink
              goTo={`/search?jobs_type=Part-time`}
              title='Part Time Jobs'
              jobType={'Part-time'}
            />
          </li>
          <li>
            <UIJobsTypeLink
              goTo={`/search?jobs_type=Full-time`}
              title='Full Time Jobs'
              jobType={'Full-time'}
            />
          </li>
          <li>
            <UIButtonLink
              title={'Login / Register'}
              callback={() => {
                dispatch(openModal('login'));
                dispatch(closeDrawer());
              }}
              classModificator={'Button__link--login'}
            />
          </li>
        </ul>
      </>
    );
  };

  const DrawerCandidateContent = () => {
    return (
      <>
        <ul className={styles.candidate_nav_list}>
          <li
            className={styles.candidate_nav_list_link}
            onClick={() => dispatch(closeDrawer())}
          >
            <UIDrawerNavLink
              title={'My Jobs'}
              goTo={'/search'}
              icon={'myJobs'}
            />
          </li>
          <li
            className={styles.candidate_nav_list_link}
            onClick={() => dispatch(closeDrawer())}
          >
            <UIDrawerNavLink
              title={'Profile'}
              goTo={'profile'}
              icon='myProfile'
            />
          </li>
        </ul>

        <ul className={styles.drawer_list}>
          <li>
            <UIButtonLink
              title={webContent.go_to_job_poster_page_web}
              callback={() => {
                navigate('/employer');
                dispatch(closeDrawer());
              }}
              classModificator={
                'Button__link--for-employers Button__link--startIcon'
              }
            />
          </li>
          <li>
            <UIJobsTypeLink
              goTo={`/search?jobs_type=Part-time`}
              title='Part Time Jobs'
              jobType={'Part-time'}
            />
          </li>
          <li>
            <UIJobsTypeLink
              goTo={`/search?jobs_type=Full-time`}
              title='Full Time Jobs'
              jobType={'Full-time'}
            />
          </li>
          <li>
            <Logout forUser={'candidate'} />
          </li>
        </ul>
      </>
    );
  };

  return (
    <div className={`${styles.drawer} ${styles.candidate}`}>
      {token ? DrawerCandidateContent() : DrawerNotAuthCandidateContent()}
    </div>
  );
};

export default CandidateDrawer;
