import React, {
  ChangeEventHandler,
  Dispatch,
  FC,
  SetStateAction,
  useState,
} from 'react';
import { FormControl, IconButton, Input, InputAdornment } from '@mui/material';
import { ReactComponent as PassLogo } from '../../../assets/icons/login-pass-loc.svg';
import UIInputLabelLogin from '../UIInputLabel/UIInputLabelLogin';

interface UILoginPassProps {
  idInput: string;
  error?: boolean;
  value?: string;
  repeat?: boolean;
  color: 'primary' | 'secondary';
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

const UILoginPass: FC<UILoginPassProps> = ({
  idInput,
  error = false,
  repeat = false,
  color,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(prev => !prev);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <div className='auth__pass'>
      <FormControl variant='standard'>
        <UIInputLabelLogin color={color} htmlFor={'auth-pass'}>
          {repeat ? 'Repeat password' : 'Password'}
        </UIInputLabelLogin>
        <div className='auth__pass--input'>
          <Input
            required={true}
            id={`auth-pass${idInput}`}
            aria-describedby='auth-password'
            color={color}
            type={showPassword ? 'text' : 'password'}
            inputProps={{
              'aria-label': 'weight',
              style: {
                color: '#35435E',
                fontWeight: 400,
                fontSize: '20px',
                lineHeight: '25px',
              },
            }}
            sx={{
              width: '100%',
              fontSize: '20px',
              fontWeight: 400,
            }}
            endAdornment={
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  <PassLogo />
                </IconButton>
              </InputAdornment>
            }
            error={error}
            {...rest}
          />
        </div>
      </FormControl>
    </div>
  );
};

export default UILoginPass;
