import React, { FC, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import Icon from '../UIIcon/UIIcon';
import { modalDialogSlice } from '../../../redux/reducers/ui/ModalDialogSlice';
import { useAppDispatch } from '../../../core/hooks/reduxHooks';

type UIModalDialogHeaderProps = {
  heading?: string;
};

const UIModalDialogHeader: FC<UIModalDialogHeaderProps> = ({ heading }) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { closeModal } = modalDialogSlice.actions;

  const currentModalStyle = useCallback(() => {
    const currentPathCandidate = location.pathname.includes('/candidate');
    const currentPathEmployer = location.pathname.includes('/employer');
    const currentPathAdmin = location.pathname.includes('/admin');

    return currentPathCandidate
      ? 'candidate__header'
      : currentPathEmployer
      ? 'employer_header'
      : currentPathAdmin
      ? 'admin_header'
      : 'candidate__header';
  }, [location.pathname]);

  return (
    <div className={`modal__header ${currentModalStyle()}`}>
      <p className='modal__heading'>{heading}</p>
      <button
        className='close_btn'
        type='button'
        onClick={() => dispatch(closeModal())}
      >
        <Icon name={'close'} className={'close_btn_icon'} />
      </button>
    </div>
  );
};

export default UIModalDialogHeader;
