import React, { FC, useEffect, useMemo, useState } from 'react';
import styles from './_styles.module.scss';
import CustomizedTables from './CustomizedTables/CustomizedTables';
import { useWindowSize } from '../../../core/hooks/useWindowSize';
import PurchaseList from './PurchaseList/PurchaseList';
import { useAppDispatch, useAppSelector } from '../../../core/hooks/reduxHooks';
import UIButton from '../../UI/UIButton/UIButton';
import UIPagination from '../../UI/UIPagination/UIPagination';
import { getInvoicePdfDetails } from '../../../core/services/axios';
import { setLoading } from '../../../redux/reducers/ui/HandlerSlice';
import { getEmployerInvoices } from '../../../redux/thunk/employer';

const PurchaseHistory: FC = () => {
  const [isPageNumber, setPageNumber] = useState<number>(1);
  const size = useWindowSize();
  const dispatch = useAppDispatch();

  const { data } = useAppSelector(state => state.UserInvoicesReducer);
  const { isLoading } = useAppSelector(state => state.UIHandlerReducer);

  useEffect(() => {
    dispatch(getEmployerInvoices({ page_number: isPageNumber, page_size: 10 }));
  }, [isPageNumber]);

  const downloadInvoice = (id: string) => {
    return (
      <UIButton
        classModificator={styles.download_btn}
        title={'Download'}
        callback={async () => {
          dispatch(setLoading(true));
          await getInvoicePdfDetails(id).then(() =>
            dispatch(setLoading(false))
          );
        }}
      />
    );
  };

  const preparedTableRowData = useMemo(() => {
    dispatch(setLoading(true));
    if (data.results) {
      const preparedData = data.results.map(invoice => ({
        created_at: invoice.created_at,
        package: invoice.package ? invoice.package : '-',
        shortlists_amount: invoice.shortlists_amount,
        reposts_amount: invoice.reposts_amount,
        posts_amount: invoice.posts_amount,
        validity_days: invoice.validity_days
          ? `${invoice.validity_days} days`
          : '-',
        total_price: `$${invoice.total_price}`,
        downloadBtn: downloadInvoice(invoice.purchase_id),
        purchase_id: invoice.purchase_id,
      }));

      dispatch(setLoading(false));

      return preparedData;
    } else {
      dispatch(setLoading(false));

      return [];
    }
  }, [data]);

  return (
    <section className={styles.purchase_history}>
      {preparedTableRowData.length > 0 ? (
        <>
          {size.width > 757 ? (
            <CustomizedTables historyData={preparedTableRowData} />
          ) : (
            <PurchaseList historyData={preparedTableRowData} />
          )}
          {data.totalPages > 1 && (
            <UIPagination
              className={styles.pagination}
              currentPage={isPageNumber}
              totalPages={data.totalPages}
              onChangePage={(event, page) => {
                setPageNumber(page);
              }}
              size={size.width < 757 ? 'small' : 'medium'}
            />
          )}
        </>
      ) : (
        <div />
      )}
      {preparedTableRowData.length === 0 && !isLoading && (
        <h3
          style={{ margin: '80px', fontSize: '24px', textAlign: 'center' }}
        >{`You don't have any purchases yet`}</h3>
      )}
    </section>
  );
};

export default PurchaseHistory;
